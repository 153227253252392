// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    banks: []
};

const slice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        getBankSuccess(state, action) {
            state.banks = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBanks() {
    return async () => {
        try {
            const response = await axios.get('getBankAccounts?pageIndex=0&pageSize=200');
            dispatch(slice.actions.getBankSuccess(response.data.data.BankList));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Field, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { fetchProxyData, getActiveBanks, getActiveDebitBanks } from 'utils/api';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| BANK ADD DIALOG ||============================== //

const AddPayoutMeta = ({ id, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [bouncers, setBouncers] = useState([]);

    const fetchDataFromAPI = async () => {
        try {
            let result = await fetchProxyData(0, 100);
            result = result?.content?.map(({ id, label }) => ({
                value: id,
                label
            }));
            setBouncers(result || []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const validationSchema = yup.object({
        label: yup.string().required('Required Field'),
        email: yup.string().required('Required Field'),
        encryptionKey: yup.string().required('Required Field'),
        agId: yup.string().required('Required Field'),
        meId: yup.string().required('Required Field'),
        sessionId: yup.string().required('Required Field'),
        uid: yup.string().required('Required Field')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open?.metaId) {
                const payload = data;
                setLoading(true);
                axiosServices
                    .put('updatePayoutmeta', payload)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        setDetails({});
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                const payload = { ...data, pgLabelId: id };
                payload.bouncerId = payload.bouncerId.value;
                setLoading(true);
                axiosServices
                    .post('createPayoutmeta', payload)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        setDetails({});
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding bank',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.metaId) {
            setDetails(open);
            formik.resetForm();
        }
    }, [open]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.metaId ? 'Update Payout' : 'Add Payout'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="label"
                                    name="label"
                                    label="Label"
                                    value={formik.values.label}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.label)}
                                    helperText={formik.errors.label}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="encryptionKey"
                                    name="encryptionKey"
                                    label="Encryption Key"
                                    value={formik.values.encryptionKey}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.encryptionKey)}
                                    helperText={formik.errors.encryptionKey}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="agId"
                                    name="agId"
                                    label="agId"
                                    onChange={formik.handleChange}
                                    value={formik.values.agId}
                                    error={Boolean(formik.errors.agId)}
                                    helperText={formik.errors.agId}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="meId"
                                    name="meId"
                                    label="Me Id"
                                    onChange={formik.handleChange}
                                    value={formik.values.meId}
                                    error={Boolean(formik.errors.meId)}
                                    helperText={formik.errors.meId}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="sessionId"
                                    name="sessionId"
                                    label="Session Id"
                                    onChange={formik.handleChange}
                                    value={formik.values.sessionId}
                                    error={Boolean(formik.errors.sessionId)}
                                    helperText={formik.errors.sessionId}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="uid"
                                    name="uid"
                                    label="UID"
                                    onChange={formik.handleChange}
                                    value={formik.values.uid}
                                    error={Boolean(formik.errors.uid)}
                                    helperText={formik.errors.uid}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    id="bouncerId"
                                    className="bouncerId-select"
                                    name="bouncerId"
                                    options={bouncers?.length ? bouncers : []}
                                    onChange={(e, value) => {
                                        formik.setFieldValue('bouncerId', value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                formik.setFieldValue('bouncerId', value);
                                            }}
                                            label="Bouncer"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddPayoutMeta.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddPayoutMeta;

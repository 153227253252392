import React, { memo, useEffect, useContext } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import DefaultMenuItems, { SuperAdminMenuItems, AdminMenuItems, AgentMenuItems, DeveloperMenuItems, SubAdminMenuItems } from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';
import JWTContext from 'contexts/JWTContext';
import LAYOUT_CONST, { ROLE_ADMIN, ROLE_AGENT, ROLE_SUBADMIN, ROLE_SUPERADMIN } from '../../../constant';
import { FormattedMessage } from 'react-intl';
import { fetchPG } from 'utils/api';
import { IconDashboard, IconSitemap } from '@tabler/icons';
import { useLocation } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuByRole = (role) => {
    const location = useLocation();

    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchPG();
            setLoading(false);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (role === ROLE_SUPERADMIN) {
            fetchDataFromAPI();
        }
    }, [role]);

    let defaultMenuItem = null;

    const PGMENU = {
        id: 'pg',
        title: <FormattedMessage id="PG" />,
        type: 'group',
        icon: IconSitemap,
        children: rows
            ?.filter((r) => r.showPayIn || r.showPayout)
            ?.map((r, id) => {
                let children = [];
                if (r.showPayIn && r.showPayout) {
                    children = [
                        {
                            id: 'settlement',
                            title: <FormattedMessage id="Show Payin" />,
                            type: 'item',
                            url: `/pg/payin/${r.pgLabel}`,
                            breadcrumbs: false
                        },
                        {
                            id: 'payout',
                            title: <FormattedMessage id="Show Payout" />,
                            type: 'item',
                            url: `/pg/payout/${r.pgLabel}`,
                            breadcrumbs: false
                        }
                    ];
                } else if (r.showPayIn) {
                    children = [
                        {
                            id: 'settlement',
                            title: <FormattedMessage id="Show PayIn" />,
                            type: 'item',
                            url: `/pg/payin/${r.pgLabel}`,
                            breadcrumbs: false
                        }
                    ];
                } else if (r.showPayout) {
                    children = [
                        {
                            id: 'payout',
                            title: <FormattedMessage id="Show Payout" />,
                            type: 'item',
                            url: `/pg/payout/${r.pgLabel}`,
                            breadcrumbs: false
                        }
                    ];
                }
                return {
                    id: 'request',
                    title: <FormattedMessage id={r.pgLabel} />,
                    type: 'collapse',
                    icon: IconSitemap,
                    children
                };
            })
    };

    let newSuper = { ...SuperAdminMenuItems };

    newSuper = { items: [...newSuper.items, PGMENU] };

    switch (role) {
        case ROLE_SUPERADMIN:
            defaultMenuItem = location.pathname?.includes('developer') ? DeveloperMenuItems : newSuper;
            break;
        case ROLE_ADMIN:
            defaultMenuItem = AdminMenuItems;
            break;
        case ROLE_SUBADMIN:
            defaultMenuItem = SubAdminMenuItems;
            break;
        case ROLE_AGENT:
            defaultMenuItem = AgentMenuItems;
            break;
        default:
            defaultMenuItem = DefaultMenuItems;
            break;
    }
    return defaultMenuItem;
};
const MenuList = () => {
    const theme = useTheme();
    const { user } = useContext(JWTContext);
    const { layout } = useConfig();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const menuItem = MenuByRole(user?.role);
    const handlerMenuItem = () => {
        const isFound = menuItem.items.some((element) => {
            if (element.id === 'widget') {
                return true;
            }
            return false;
        });

        // if (getMenu?.id !== undefined && !isFound) {
        //     menuItem.items.splice(3, 0, getMenu);
        // }
    };

    useEffect(() => {
        handlerMenuItem();
        // eslint-disable-next-line
    }, []);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete, MenuItem } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import useAuth from 'hooks/useAuth';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    amount: yup.string().required('Required Field').nullable(),
    conversionType: yup.string().required('Required Field').nullable(),
    referenceNumber: yup.string().required('Required Field').nullable(),
    remark: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const SettlementAction = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState();

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();

    useEffect(() => {
        if (open) {
            setDetails(open);
        }
    }, [open]);

    // function buildFormData(formData, data, parentKey) {
    //     if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    //         Object.keys(data).forEach((key) => {
    //             buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    //         });
    //     } else {
    //         const value = data == null ? '' : data;

    //         formData.append(parentKey, value);
    //     }
    // }

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            const paylaod = {
                image: file ? file.split('base64,')?.[1] : '',
                conversionType: data?.conversionType,
                // amount: data.amount,
                affiliateId: data?.affiliateId,
                status: 'SUCCESS',
                settlementId: data?.settlementId,
                referenceNumber: data?.referenceNumber,
                remark: data?.remark,
                // conversionRate: data?.conversionRate || 0
                settledAmount: data?.settledAmount || 0
            };

            setLoading(true);
            axiosServices
                .post('updateAffiliateSettlementStatus', paylaod)
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Sent Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while settlement request',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    const onLoad = (fileString) => {
        setFile(fileString);
    };

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onLoad(reader.result);
        };
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // sx={{
            //     '&>div:nth-of-type(3)': {
            //         justifyContent: 'flex-end',
            //         '&>div': {
            //             m: 0,
            //             borderRadius: '0px',
            //             maxWidth: 450,
            //             maxHeight: '100%'
            //         }
            //     }
            // }}
        >
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Approve Request</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    // id="conversionFees"
                                    // name="conversionFees"
                                    label="Request Type"
                                    type="number"
                                    disabled
                                    value={formik.values?.valueType}
                                    // onChange={formik.handleChange}
                                    // error={Boolean(formik.errors?.conversionFees)}
                                    // helperText={formik.errors?.conversionFees}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="conversionType"
                                    name="conversionType"
                                    disabled
                                    label="Conversion Type"
                                    value={formik.values?.conversionType || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.conversionType)}
                                    helperText={formik.errors?.conversionType}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="amount"
                                    name="amount"
                                    disabled
                                    label="Amount"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values?.amount}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.amount)}
                                    helperText={formik.errors?.amount}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="settledAmount"
                                    name="settledAmount"
                                    label="Settled Amount"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values?.settledAmount}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.settledAmount)}
                                    helperText={formik.errors?.settledAmount}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="referenceNumber"
                                    name="referenceNumber"
                                    label="Reference No"
                                    value={formik.values?.referenceNumber || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.referenceNumber)}
                                    helperText={formik.errors?.referenceNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="remark"
                                    name="remark"
                                    label="Remark"
                                    value={formik.values?.remark}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.remark)}
                                    helperText={formik.errors?.remark}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <input type="file" onChange={handleUploadFile} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

SettlementAction.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default SettlementAction;

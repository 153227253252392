import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    IconButton,
    TablePagination,
    Stack
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchProxyData } from 'utils/api';
import { Delete, Download, Edit, RefreshOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from 'ui-component/Loader';
import Chip from 'ui-component/extended/Chip';
import AddProxy from './AddProxy';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'proxyname',
        numeric: false,
        label: 'Label',
        align: 'left'
    },
    {
        id: 'url',
        numeric: true,
        label: 'Proxy IP',
        align: 'left'
    },
    {
        id: 'isGet',
        numeric: false,
        label: 'IS GET',
        align: 'left'
    }
];

const date = new Date();
const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
const currentMonth = date.getMonth() < 10 ? `0${parseInt(date.getMonth(), 10) + 1}` : `${parseInt(date.getMonth(), 10) + 1}`;
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

const Proxy = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [selectedProxy, setSelectedProxy] = React.useState(false);

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await fetchProxyData(page, rowsPerPage);
            setLoading(false);
            setData(result.content);
            setRows(result.content || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const deleteProxy = () => {
        axiosServices
            .delete(`deletePayoutProxyById/${selectedProxy?.id}`)
            .then((res) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Proxy Deleted',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                fetchDataFromAPI(page, rowsPerPage);
                dispatch(closeSnackbar);
                setSelectedProxy(false);
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while deleting Proxy',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Proxy</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent sx={{ p: 0 }}>
                <Grid sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2} />
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add Proxy
                        </Button>
                        <AddProxy open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: 500, width: 300 }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                    >
                                        <div>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                            <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.label}</TableCell>
                                    <TableCell>{row.proxyIp}</TableCell>
                                    <TableCell>{row.isGet}</TableCell>
                                    <TableCell align="center">
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Delete">
                                                <IconButton
                                                    onClick={() => setSelectedProxy(row)}
                                                    color="primary"
                                                    sx={{
                                                        color: theme.palette.orange.dark,
                                                        borderColor: theme.palette.orange.main,
                                                        '&:hover ': { background: theme.palette.orange.light }
                                                    }}
                                                    size="large"
                                                >
                                                    <DeleteIcon sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                open={selectedProxy}
                title="Delete Proxy"
                subTitle="Are you sure, you want to delete selected Proxy"
                handleClose={() => setSelectedProxy(false)}
                handleSubmit={deleteProxy}
            />

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.totalSize || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Proxy;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    settledAmount: yup.string().required('Required Field').nullable(),
    remark: yup.string().required('Required Field').trim()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddCredit = ({ merchants, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (!open?.manualSettledEntityId?.settlementId) {
                if (!data?.merchantId?.id) {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Select merchant',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                    return;
                }
                const payload = {
                    merchantId: data?.merchantId?.id,
                    settledAmount: data.settledAmount,
                    remark: data.remark,
                    name: data?.merchantId?.label
                };
                setLoading(true);

                axiosServices
                    .post('payout/manualPayoutCredit', payload)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: res?.data?.message || 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);

                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                const payload = {
                    merchantId: data?.manualSettledEntityId?.merchantId,
                    settlementId: data.manualSettledEntityId?.settlementId,
                    settledAmount: data.settledAmount,
                    remark: data.remark
                };
                setLoading(true);
                axiosServices
                    .put('updateSettledTransaction', payload)
                    .then((res) => {
                        setLoading(false);

                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding merchantid',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.manualSettledEntityId?.merchantId) {
            formik.setFieldValue('merchantId', open?.manualSettledEntityId?.merchantId);
            setDetails(open);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // sx={{
            //     '&>div:nth-of-type(3)': {
            //         justifyContent: 'flex-end',
            //         '&>div': {
            //             m: 0,
            //             borderRadius: '0px',
            //             maxWidth: 450,
            //             maxHeight: '100%'
            //         }
            //     }
            // }}
        >
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.manualSettledEntityId ? 'Edit Manual Payout' : 'Add Manual Payout'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        {open?.manualSettledEntityId ? (
                            <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="settledAmount"
                                        name="settledAmount"
                                        label="Amount"
                                        type="number"
                                        onWheel={() => document.activeElement.blur()}
                                        value={formik.values.settledAmount}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.settledAmount)}
                                        helperText={formik.errors.settledAmount}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="remark"
                                        name="remark"
                                        label="Remark"
                                        value={formik.values.remark}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.remark)}
                                        helperText={formik.errors.remark}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="merchantId"
                                        className="merchantId-select"
                                        name="merchantId"
                                        options={merchants?.length ? merchants : []}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('merchantId', value);
                                        }}
                                        // onChange={formik.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('merchantId', value);
                                                }}
                                                label="Merchant Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="settledAmount"
                                        name="settledAmount"
                                        label="Amount"
                                        type="number"
                                        onWheel={() => document.activeElement.blur()}
                                        value={formik.values.settledAmount}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.settledAmount)}
                                        helperText={formik.errors.settledAmount}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="remark"
                                        name="remark"
                                        label="Remark"
                                        value={formik.values.remark}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.remark)}
                                        helperText={formik.errors.remark}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddCredit.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddCredit;

import React, { useEffect } from 'react';

// material-ui
import { Button, Grid, Table, TableRow, TableBody, TableCell, TableContainer, CardContent, Tooltip, IconButton } from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchPG } from 'utils/api';
import { Delete, Edit, RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddPG from './AddPG';
import Chip from 'ui-component/extended/Chip';
import { ISTDate } from 'constant';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'pgLabel',
        numeric: false,
        label: 'PG Label',
        align: 'left'
    },
    {
        id: 'payin',
        numeric: true,
        label: 'PayIn',
        align: 'left'
    },
    {
        id: 'PayOut',
        numeric: false,
        label: 'PayOut',
        align: 'left'
    },
    {
        id: 'Action',
        numeric: false,
        label: 'Action',
        align: 'center'
    }
];

const PG = () => {
    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchPG();
            setLoading(false);
            setAllData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI();
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>PG</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
            content={false}
        >
            {loading && <Loader />}
            <CardContent>
                <Grid sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2} />
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add PG
                        </Button>
                        <AddPG open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell align="center">{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                    <TableCell>{row.pgLabel}</TableCell>
                                    <TableCell>
                                        <Chip chipcolor={row.showPayIn ? 'success' : 'error'} label={row.showPayIn ? 'YES' : 'NO'} />
                                    </TableCell>
                                    <TableCell>
                                        <Chip chipcolor={row.showPayout ? 'success' : 'error'} label={row.showPayout ? 'YES' : 'NO'} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
};

export default PG;

import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    IconButton,
    TablePagination,
    Stack,
    Autocomplete,
    TextField,
    MenuItem
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchMerchants, fetchIPData, fetchActiveMerchants } from 'utils/api';
import { Check, CloseOutlined, RefreshOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from 'ui-component/Loader';
import AddIPWhitelist from './AddIPWhitelist';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'merchant',
        numeric: false,
        label: 'Merchant',
        align: 'left'
    },
    {
        id: 'IPvalue',
        numeric: true,
        label: 'IP',
        align: 'left'
    },
    {
        id: 'Status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

const date = new Date();
const currentDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
const currentMonth = date.getMonth() < 10 ? `0${parseInt(date.getMonth(), 10) + 1}` : `${parseInt(date.getMonth(), 10) + 1}`;
const currentYear = date.getFullYear();
const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

const IPWhitelist = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [selectedProxy, setSelectedProxy] = React.useState(false);

    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState();

    const [field, setField] = React.useState({
        label: '',
        value: ''
    });

    const [editFields, setEditFields] = React.useState({
        active: false,
        id: ''
    });

    const resetEditFields = () => {
        setEditFields({ active: false, id: '', merchantId: '', ipValue: '' });
        setField({ label: '', value: '' });
    };

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await fetchActiveMerchants();
            result = result.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchIPData(selectedMerchant?.id);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (selectedMerchant) {
            fetchDataFromAPI();
        }
    }, [selectedMerchant]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const handleSave = () => {
        if (!field.value) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'please select status',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        } else {
            axiosServices
                .put(`updateIpStatus`, {
                    id: editFields.id,
                    status: field.value,
                    ipValue: editFields.ipValue,
                    merchantId: editFields.merchantId
                })
                .then((res) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Field updated successfully!',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                    resetEditFields();
                    fetchDataFromAPI();
                })
                .catch((e) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while updating field',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>IP Whitelist</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                        <Autocomplete
                            id="merchant-select"
                            options={merchants || []}
                            onChange={(e, value) => {
                                setSelectedMerchant(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    label="Merchants"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add IP
                        </Button>
                        <AddIPWhitelist merchants={merchants} open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell
                                        align="center"
                                        style={{ fontWeight: 500, width: 300 }}
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                    >
                                        <div>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                            <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.merchantId}</TableCell>
                                    <TableCell>{row.ipValue}</TableCell>
                                    <TableCell>
                                        {editFields.active && editFields.id === row.id ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="active"
                                                    size="small"
                                                    name="active"
                                                    label="Status"
                                                    style={{ width: 100, marginRight: 5 }}
                                                    // defaultValue={row.status === 'Active' ? 'Active' : 'DeActive'}
                                                    onChange={(e) => setField({ label: 'status', value: e.target.value })}
                                                >
                                                    {['Active', 'DeActive']?.map((method) => (
                                                        <MenuItem value={method}>{method}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <Check style={{ color: 'green', fontSize: 24, cursor: 'pointer' }} onClick={handleSave} />
                                                <CloseOutlined
                                                    style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                    onClick={resetEditFields}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                role="presentation"
                                                style={{ color: row.status === 'Active' ? 'green' : 'red', cursor: 'pointer' }}
                                                onKeyDown={() => console.log('')}
                                                value={field.value}
                                                onClick={(e) => {
                                                    setEditFields({
                                                        active: true,
                                                        id: row.id,
                                                        ipValue: row.ipValue,
                                                        merchantId: row.merchantId
                                                    });
                                                }}
                                            >
                                                {row.status}
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default IPWhitelist;

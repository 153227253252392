import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    MenuItem,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    Switch,
    FormControlLabel
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    maxManualTransferLimit: yup.string().required('Required Field'),
    maxLowBalanceLimit: yup.string().required('Required Field'),
    maxPendingLimit: yup.string().required('Required Field'),
    maxAutoTransferLimit: yup.string().required('Required Field'),
    minAutoTransferLimit: yup.string().required('Required Field'),
    maxLastFailedLimit: yup.string().required('Required Field')
});

// ==============================|| CONFIG ADD DIALOG ||============================== //

const Config = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        isAutoPayoutEnable: false,
        isPayoutStatusCallEnable: false
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axiosServices
            .get(`get/payoutConfig`)
            .then((res) => {
                setLoading(false);
                setDetails(res.data.data);
            })
            .catch((e) => {
                setLoading(false);
                console.log(e);
            });
    }, [open]);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);
            axiosServices
                .post('update/payoutConfig', data)
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleCloseDialog(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Saved Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    window.location.reload();
                })
                .catch((e) => {
                    setLoading(false);

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || e?.message || 'Error while updating details',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    const handleResetLowBal = () => {
        setLoading(true);
        axiosServices
            .post('payout/resetLowBal')
            .then((res) => {
                setLoading(false);

                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Saved Successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            })
            .catch((e) => {
                setLoading(false);

                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || e?.message || 'Error while reset',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };
    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted>
            {loading && <Loader />}

            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>Merchant Dashboard Logs</div>
                            <div />
                            {/* <Button onClick={handleResetLowBal} disabled={loading} variant="contained">
                                Reset Low Bal
                            </Button> */}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isAutoPayoutEnable', e.target.checked);
                                            }}
                                            checked={formik.values.isAutoPayoutEnable}
                                        />
                                    }
                                    label="Is Auto Payout Enable"
                                />
                            </Grid>
                            <Grid item xs={6} />

                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isPayoutStatusCallEnable', e.target.checked);
                                            }}
                                            checked={formik.values.isPayoutStatusCallEnable}
                                        />
                                    }
                                    label="Is Payout Status Call Enable"
                                />
                            </Grid>

                            <Grid item xs={6} />

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="maxManualTransferLimit"
                                    name="maxManualTransferLimit"
                                    label="Max Manual Transfer Limitt"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.maxManualTransferLimit || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.maxManualTransferLimit)}
                                    helperText={formik.errors.maxManualTransferLimit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="maxLowBalanceLimit"
                                    name="maxLowBalanceLimit"
                                    label="Max LowBal Limit"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.maxLowBalanceLimit}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.maxLowBalanceLimit)}
                                    helperText={formik.errors.maxLowBalanceLimit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="maxPendingLimit"
                                    name="maxPendingLimit"
                                    label="Max Pending Limit"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.maxPendingLimit}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.maxPendingLimit)}
                                    helperText={formik.errors.maxPendingLimit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="maxLastFailedLimit"
                                    name="maxLastFailedLimit"
                                    label="Max Last Failed Limit"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.maxLastFailedLimit}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.maxLastFailedLimit)}
                                    helperText={formik.errors.maxLastFailedLimit}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="minAutoTransferLimit"
                                    name="minAutoTransferLimit"
                                    label="Min Auto Transfer Limit"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.minAutoTransferLimit}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.minAutoTransferLimit)}
                                    helperText={formik.errors.minAutoTransferLimit}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="maxAutoTransferLimit"
                                    name="maxAutoTransferLimit"
                                    label="Max Auto Transfer Limit"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values.maxAutoTransferLimit}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.maxAutoTransferLimit)}
                                    helperText={formik.errors.maxAutoTransferLimit}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog(false);
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

Config.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default Config;

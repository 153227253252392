import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import { getActivePayoutBanks } from 'utils/api';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    payoutBankId: yup.string().required('Required Field'),
    payoutMode: yup.string().required('Required Field'),
    payoutStatus: yup.string().required('Required Field'),
    utrNumber: yup.string().required('Required Field')
});

// ==============================|| UpdatePayout ADD DIALOG ||============================== //

const UpdatePayout = ({ open, handleUpdateDialog }) => {
    const dispatch = useDispatch();
    const [updates, setUpdate] = useState({
        payoutMode: 'IMPS'
    });
    const [loading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);

    const fetchDataFromAPI = async (open) => {
        setLoading(true);
        try {
            const result = await getActivePayoutBanks();
            setLoading(false);
            if (result?.length) {
                setUpdate({ ...open, payoutBankId: result[0]?.payoutBankId });
            }
            setBanks(result);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            // setUpdate(open);
            fetchDataFromAPI(open);
        }
    }, [open]);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: updates,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);
            const payout = {
                payoutId: data.payoutId,
                payoutBankId: data.payoutBankId,
                payoutBankHolderName: banks.filter((b) => b.payoutBankId === data.payoutBankId)[0]?.accountHolderName,
                payoutMode: data.payoutMode,
                payoutStatus: data.payoutStatus,
                utrNumber: data.utrNumber
            };
            axiosServices
                .post('manualUpdatePayoutTransactions', payout)
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleUpdateDialog(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Saved Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    // window.location.reload();
                })
                .catch((e) => {
                    setLoading(false);

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || e?.message || 'Error while updating details',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    console.log(formik.values, formik.errors);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted>
            {loading && <Loader />}

            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>Process Manual Payout</div>
                            <div />
                            {/* <Button onClick={handleResetLowBal} disabled={loading} variant="contained">
                                Reset Low Bal
                            </Button> */}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    id="payoutBankId"
                                    name="payoutBankId"
                                    label="Account Details"
                                    value={formik.values?.payoutBankId || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payoutBankId)}
                                    helperText={formik.errors.payoutBankId}
                                >
                                    {banks.map((b) => (
                                        <MenuItem value={b.payoutBankId}>
                                            {b.accountHolderName} ({b.accountNumber}) {b.bankName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    id="payoutMode"
                                    name="payoutMode"
                                    label="Payout Mode"
                                    value={formik.values.payoutMode}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payoutMode)}
                                    helperText={formik.errors.payoutMode}
                                >
                                    <MenuItem value="IMPS">IMPS</MenuItem>
                                    <MenuItem value="NEFT">NEFT</MenuItem>
                                    <MenuItem value="RTGS">RTGS</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    select
                                    id="payoutStatus"
                                    name="payoutStatus"
                                    label="Payout Status"
                                    value={formik.values?.payoutStatus}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.payoutStatus)}
                                    helperText={formik.errors.payoutStatus}
                                >
                                    <MenuItem value="PENDING">PENDING</MenuItem>
                                    <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                                    <MenuItem value="FAILED">FAILED</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="utrNumber"
                                    name="utrNumber"
                                    label="Enter Bank Utr"
                                    type="number"
                                    onWheel={() => document.activeElement.blur()}
                                    value={formik.values?.utrNumber}
                                    onChange={(e) => {
                                        if (e.target.value?.length <= 12) {
                                            formik.setFieldValue('utrNumber', e.target.value);
                                        }
                                    }}
                                    error={Boolean(formik.errors.utrNumber)}
                                    helperText={formik.errors.utrNumber}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Update Payout
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                                formik.resetForm();
                                setUpdate({});
                                handleUpdateDialog(false);
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

UpdatePayout.propTypes = {
    open: PropTypes.bool,
    handleUpdateDialog: PropTypes.func
};

export default UpdatePayout;

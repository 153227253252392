import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    IconButton,
    TablePagination,
    Stack,
    Switch
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchLocData } from 'utils/api';
import { Edit, RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddURL from './AddUrl';
import ConfirmationModal from 'ui-component/Modal';
import { ROLE_SUPERADMIN } from 'constant';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import useAuth from 'hooks/useAuth';

// table header options
const headCells = [
    {
        id: 'city',
        numeric: false,
        label: 'City',
        align: 'center'
    },
    {
        id: 'State',
        numeric: false,
        label: 'State',
        align: 'center'
    },
    {
        id: 'country',
        numeric: true,
        label: 'Country',
        align: 'center'
    },
    {
        id: 'blocked',
        numeric: true,
        label: 'Blocked',
        align: 'center'
    }
];

const UnauthLocations = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [resetOpen, setResetOpen] = React.useState(false);

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await fetchLocData(page, rowsPerPage);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const handleStatus = () => {
        setLoading(true);
        axiosServices
            .put(`update/address/tracker`, {
                ...resetOpen,
                isBlocked: !resetOpen.isBlocked
            })
            .then((r) => {
                setLoading(false);
                setResetOpen(false);
                window.location.reload();
            })

            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating status',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Unauth Locations</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent sx={{ p: 0 }}>
                <Grid sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2} />
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add Location
                        </Button>
                        <AddURL open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell align="center">{row.city}</TableCell>
                                    <TableCell align="center">{row.state}</TableCell>
                                    <TableCell align="center">{row.country}</TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen(row)}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isBlocked} />
                                                {/* {row.isPayInEnable.toString()} */}
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Edit">
                                                <IconButton
                                                    onClick={() => setOpen(row)}
                                                    color="primary"
                                                    // sx={{
                                                    //     color: theme.palette.orange.dark,
                                                    //     borderColor: theme.palette.orange.main,
                                                    //     '&:hover ': { background: theme.palette.orange.light }
                                                    // }}
                                                    size="large"
                                                >
                                                    <Edit sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {user?.role === ROLE_SUPERADMIN && (
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <ConfirmationModal
                        loading={loading}
                        open={resetOpen}
                        title={`${resetOpen?.isBlocked ? 'active' : 'block'}`}
                        subTitle={`Are you sure, you want to ${resetOpen?.isBlocked ? 'active' : 'block'} location`}
                        handleClose={() => setResetOpen(false)}
                        handleSubmit={handleStatus}
                    />
                </Grid>
            )}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.totalSize || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default UnauthLocations;

import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    TablePagination,
    IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchActiveMerchants, fetchMerchVpnData } from 'utils/api';
import { Delete, Edit, RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddConfig from './AddConfig';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';
import { Stack } from '@mui/system';

// table header options
const headCells = [
    {
        id: 'merchant',
        numeric: false,
        label: 'Merchant',
        align: 'center'
    },
    {
        id: 'vpn',
        numeric: true,
        label: 'VPN',
        align: 'left'
    },
    {
        id: 'city',
        numeric: true,
        label: 'City',
        align: 'left'
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
        align: 'center'
    }
];

const MerchantVPN = () => {
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const [delete1, setDelete] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [merchants, setMerchants] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await fetchActiveMerchants();
            result = result.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchMerchVpnData(page, rowsPerPage);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI();
    };

    const handleDelete = () => {
        if (delete1) {
            axiosServices
                .delete(`deleteMerchantProfileMeta/${delete1?.merchantId}`)
                .then((res) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Deleted',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    fetchDataFromAPI();
                    dispatch(closeSnackbar);
                    setDelete(false);
                })
                .catch((e) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while deleting config',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Merchant VPN</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item xs={8} />

                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add VPN
                        </Button>
                        <AddConfig merchants={merchants} open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            return (
                                <TableRow hover>
                                    <TableCell align="center">
                                        <div>{row.merchantId}</div>
                                        <div>{row.merchantName}</div>
                                    </TableCell>
                                    <TableCell>{row.isVpnEnable ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{row.city ? JSON.stringify(row.city) : ''}</TableCell>
                                    <TableCell align="center">
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Tooltip placement="top" title="Edit">
                                                <IconButton color="primary" aria-label="edit" size="large">
                                                    <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip placement="top" title="Delete">
                                                <IconButton color="primary" aria-label="delete" size="large">
                                                    <Delete onClick={() => setDelete(row)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                                </IconButton>
                                            </Tooltip> */}
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {delete1 && (
                <ConfirmationModal
                    loading={loading}
                    open={delete1}
                    title="Delete Config"
                    subTitle="Are you sure, you want to delete config"
                    handleClose={() => {
                        setDelete(false);
                    }}
                    handleSubmit={handleDelete}
                />
            )}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default MerchantVPN;

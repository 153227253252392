import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    CardContent,
    Autocomplete,
    TextField,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchMerchants, fetchIPData } from 'utils/api';
import Loader from 'ui-component/Loader';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyAll } from '@mui/icons-material';
import Add from './Add';

const CreatePayout = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [copy, setCopy] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState();

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await fetchMerchants();
            result = result.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleCreate = () => {
        if (!selectedMerchant) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'please select merchant',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        } else {
            setLoading(true);
            axiosServices
                .get(`generateKey/${selectedMerchant?.id}`)
                .then((res) => {
                    setOpen(res?.data?.data?.key);
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while generating secret',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Payout Transaction</div>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    {/* <Grid item>
                        <Autocomplete
                            id="merchant-select"
                            options={merchants || []}
                            onChange={(e, value) => {
                                setSelectedMerchant(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    label="Merchants"
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item>
                        <Button style={{ marginLeft: 20 }} size="small" variant="contained" onClick={() => setOpen(true)}>
                            Create Payout Transaction
                        </Button>
                    </Grid>
                </Grid>
                <Add merchants={merchants} open={open} handleCloseDialog={handleCloseDialog} />
            </CardContent>
        </MainCard>
    );
};

export default CreatePayout;

import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import * as React from 'react';
import axiosServices from 'utils/axios';

// table header options
const headCells = [
    {
        id: 'id',
        label: 'ID',
        align: 'left'
    },
    {
        id: 'name',
        label: 'Name',
        align: 'left'
    },
    {
        id: 'levels',
        label: 'Levels',
        align: 'left'
    }
];

// ==============================|| MERCHANT BANKS ||============================== //

const MerchantBanks = ({ id, refetch }) => {
    const theme = useTheme();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();

    React.useEffect(() => {
        axiosServices.get(`distinct-levels?pageIndex=${page}&pageSize=${rowsPerPage}`).then((res) => {
            setRows(res.data?.data?.metaList || ['test']);
            setData(res.data?.data);
        });
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <TableContainer sx={{ maxHeight: '40vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.merchantId}</TableCell>
                                <TableCell>
                                    <div>{row.merchantName}</div>
                                </TableCell>
                                <TableCell align="center">
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    defaultChecked={row.distinctLevels?.toString()?.includes?.('Level1')}
                                                    disabled
                                                />
                                            }
                                            label="Level 1"
                                        />
                                        <FormControlLabel
                                            required
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    defaultChecked={row.distinctLevels?.toString()?.includes?.('Level2')}
                                                    disabled
                                                />
                                            }
                                            label="Level 2"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    defaultChecked={row.distinctLevels?.toString()?.includes?.('Level3')}
                                                    disabled
                                                />
                                            }
                                            label="Level 3"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    defaultChecked={row.distinctLevels?.toString()?.includes?.('Level4')}
                                                    disabled
                                                />
                                            }
                                            label="Level 4"
                                        />
                                    </FormGroup>
                                </TableCell>
                            </TableRow>
                        ))}
                        {rows?.length === 0 && <div style={{ marginTop: 10, color: 'red' }}>No Data</div>}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.totalSize || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default MerchantBanks;

import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@mui/material';

// animation
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// ===============================|| UI DIALOG - SLIDE ANIMATION ||=============================== //

export default function ConfirmationModal({ loading, open, title, subTitle, handleClose, handleSubmit }) {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title1"
            aria-describedby="alert-dialog-slide-description1"
        >
            {open && (
                <>
                    <DialogTitle id="alert-dialog-slide-title1">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description1">
                            <Typography variant="body2" component="span">
                                {subTitle}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            disabled={loading}
                            sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                            onClick={handleClose}
                            color="secondary"
                        >
                            No
                        </Button>
                        <Button disabled={loading} variant="contained" size="small" onClick={handleSubmit}>
                            Yes
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    label: yup.string().required('Required Field').nullable(),
    url: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddProxy = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        label: '',
        url: ''
    });

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open?.id) {
                setLoading(true);
                axiosServices
                    .put('editCheckOutUrl', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        setDetails({
                            label: '',
                            url: ''
                        });
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: res?.data?.message || 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                setLoading(true);
                axiosServices
                    .post('addCheckOutUrl', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        setDetails({
                            label: '',
                            url: ''
                        });
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: res?.data?.message || 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.label) {
            setDetails(open);
        }
    }, [open]);

    console.log(formik.values, formik.errors);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="sm" keepMounted>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{open?.label ? 'Edit URL' : 'Add URL'}</DialogTitle>
                <DialogContent>
                    {loading && <Loader />}

                    <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="label"
                                    name="label"
                                    label="Label"
                                    disabled={open?.label}
                                    value={formik.values.label}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.label)}
                                    helperText={formik.errors.label}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="url"
                                    name="url"
                                    label="URL"
                                    value={formik.values.url}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.url)}
                                    helperText={formik.errors.url}
                                />
                            </Grid>
                        </>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <AnimateButton>
                        <Button disabled={loading} type="submit" variant="contained">
                            Save
                        </Button>
                    </AnimateButton>
                    <Button
                        variant="text"
                        color="error"
                        disabled={loading}
                        onClick={() => {
                            formik.resetForm();
                            setDetails({
                                label: '',
                                url: ''
                            });
                            handleCloseDialog();
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

AddProxy.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddProxy;

import * as React from 'react';

// material-ui
import {
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch } from 'store';

// assets
import SearchIcon from '@mui/icons-material/Search';
import axiosServices from 'utils/axios';
import { fetchAllUPIApps } from 'utils/api';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import ConfirmationModal from 'ui-component/Modal';
import useAuth from 'hooks/useAuth';
import { ROLE_SUPERADMIN } from 'constant';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';

// table header options
const headCells = [
    {
        id: 'isPhonePayEnable',
        numeric: true,
        label: 'PhonePe',
        align: 'center'
    },
    {
        id: 'isGooglePayEnable',
        numeric: false,
        label: 'GooglePay',
        align: 'center'
    },
    {
        id: 'isPaytmEnable',
        numeric: true,
        label: 'Paytm',
        align: 'center'
    },
    {
        id: 'isUpiEnable',
        numeric: true,
        label: 'More UPI',
        align: 'center'
    }
];

// ==============================|| CHECKOUT UPI APPS ||============================== //

const UPIApps = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    // show a right sidebar when clicked on new product
    const [resetOpen, setResetOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [search, setSearch] = React.useState('');
    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const [copy, setCopy] = React.useState(false);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchAllUPIApps();
            setLoading(false);
            setRows([result]);
            setAllRows([result]);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleStatus = () => {
        setLoading(true);

        const payload = {
            ...resetOpen.data,
            isGooglePayEnable:
                resetOpen.app === 'gpay' ? (resetOpen?.data?.isGooglePayEnable ? 'false' : 'true') : resetOpen?.data?.isGooglePayEnable,
            isPhonePayEnable:
                resetOpen.app === 'phonepe' ? (resetOpen?.data?.isPhonePayEnable ? 'false' : 'true') : resetOpen?.data?.isPhonePayEnable,
            isPaytmEnable: resetOpen.app === 'paytm' ? (resetOpen?.data?.isPaytmEnable ? 'false' : 'true') : resetOpen?.data?.isPaytmEnable,
            isUpiEnable: resetOpen.app === 'any' ? (resetOpen?.data?.isUpiEnable ? 'false' : 'true') : resetOpen?.data?.isUpiEnable
        };

        axiosServices
            .put(`updateUpiAppOption`, payload)
            .then((r) => {
                setLoading(false);
                setResetOpen(false);
                fetchDataFromAPI();
            })

            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating status',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard title="Enable/Disable UPI Apps" content={false}>
            {loading && <Loader />}
            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            console.log(row);
                            return (
                                <TableRow hover>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen({ data: row, app: 'phonepe' })}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isPhonePayEnable} />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen({ data: row, app: 'gpay' })}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isGooglePayEnable} />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen({ data: row, app: 'paytm' })}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isPaytmEnable} />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen({ data: row, app: 'any' })}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isUpiEnable} />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {user?.role === ROLE_SUPERADMIN && (
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <ConfirmationModal
                        loading={loading}
                        open={resetOpen?.data}
                        title="Status Change"
                        subTitle={`Are you sure, you want to change status of ${resetOpen?.app}`}
                        handleClose={() => setResetOpen(false)}
                        handleSubmit={handleStatus}
                    />
                </Grid>
            )}
        </MainCard>
    );
};

export default UPIApps;

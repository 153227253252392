import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import { AVAILABLE_METHODS } from 'constant';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanks } from 'store/slices/bank';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// table header options
const headCells = [
    {
        id: 'name',
        label: 'Account Holder',
        align: 'left'
    },
    {
        id: 'account',
        label: 'Account No',
        align: 'left'
    },
    {
        id: 'UPI',
        label: 'UPI',
        align: 'left'
    },
    {
        id: 'Bank',
        label: 'Bank',
        align: 'left'
    },
    {
        id: 'methods',
        label: 'Available Methods',
        align: 'left'
    },
    {
        id: 'level',
        label: 'Level',
        align: 'left'
    }
];

// ==============================|| MERCHANT BANKS ||============================== //

const PayInMeta = ({ setRefetch }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState([]);
    const [selectedBank, setSelectedBank] = React.useState(false);
    const [selectedMethod, setSelectedMethod] = React.useState();

    React.useEffect(() => {
        axiosServices.get(`getActiveBanksMeta`).then((res) => {
            setRows(res?.data?.data?.banks || []);
        });
    }, []);

    return (
        <div>
            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell sx={{ height: '8vh' }}>{row.accountHolderName}</TableCell>
                                <TableCell>{row.accountNumber}</TableCell>
                                <TableCell>{row.upiId}</TableCell>
                                <TableCell>{row.bankName}</TableCell>
                                <TableCell>{row.availableMethods}</TableCell>
                                <TableCell>{row.level}</TableCell>
                                {/* <TableCell align="center">
                                    <Button onClick={() => setSelectedBank(row)} variant="contained" size="small">
                                        Add Meta
                                    </Button>
                                </TableCell> */}
                            </TableRow>
                        ))}
                        {rows?.length === 0 && <div style={{ marginTop: 10, color: 'red' }}>No Data</div>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default PayInMeta;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Field, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { getActiveBanks, getActiveDebitBanks } from 'utils/api';
import Loader from 'ui-component/Loader';
import { AMOUNT_REGEX, NO_REGEX } from 'constant';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| BANK ADD DIALOG ||============================== //

const AddTransaction = ({ bank, id, open, handleCloseDialog, refetchBalance }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        mode: 'CR'
    });
    const [bankNames, setBankNames] = useState([]);
    const [debitBankNames, setDebitBankNames] = useState([]);
    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        bankId: id || bank === 'all' ? null : yup.object().required('Required Field'),
        payMethod: yup.string().required('Required Field'),
        mode: yup.string().required('Required Field'),
        amount: yup.number().typeError('Amount must be a number').required('Required Field').nullable(),
        transactionUTR: yup
            .string()
            // .typeError('UTR must be a number')
            .required('Required Field')
            .nullable()
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open?.bankTransactionId) {
                const payload = {
                    bankId: open?.bankTransactionId?.bankId,
                    bankTransactionId: open?.bankTransactionId?.bankTransactionId,
                    amount: data.amount,
                    utrNumber: data.transactionUTR,
                    agentRemark: data.agentRemark
                };
                setLoading(true);
                axiosServices
                    .put('updateBankTransaction', payload)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        setDetails({ mode: 'CR' });
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else if (data.mode === 'DR' && !data.debitBankId) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Select debit bank',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            } else {
                const payload = { ...data };
                payload.bankId = data.bankId?.value;
                payload.debitBankId = data.debitBankId?.value || null;
                setLoading(true);
                axiosServices
                    .post('addBankTransaction', payload, {
                        validateStatus() {
                            return true;
                        }
                    })
                    .then((res) => {
                        setLoading(false);
                        if (res?.data?.statusCode === 409) {
                            throw new Error('Record Exist');
                        }
                        // resetForm();
                        // handleCloseDialog();
                        formik.setFieldValue('amount', '');
                        formik.setFieldValue('transactionUTR', '');
                        formik.setFieldValue('agentRemark', '');
                        formik.setFieldValue('mode', 'CR');
                        formik.setFieldValue('debitBankId', null);
                        formik.setFieldValue('payMethod', 'UPI');
                        if (refetchBalance) {
                            refetchBalance();
                        }
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: res?.data?.message || 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    const fetchDataFromAPI = async () => {
        try {
            let result = await getActiveBanks();
            result = result.map(({ bankId, bankName, accountHolderName }) => ({
                value: bankId,
                label: `${accountHolderName} - ${bankName}`
            }));
            if (id) {
                setBankNames(result.filter((r) => r?.value === id));
                setBankNames(result.filter((r) => r?.value === id));
                formik.setFieldValue('bankId', result.filter((r) => r?.value === id)[0]);
                formik.setFieldValue('payMethod', 'UPI');
                formik.setFieldValue('mode', 'CR');
            } else {
                setBankNames(result);
            }
        } catch (error) {
            console.error(error);
        }
        try {
            let result = await getActiveDebitBanks();
            result = result.map(({ debitBankId, bankName, accountHolderName }) => ({
                value: debitBankId,
                label: `${accountHolderName} - ${bankName}`
            }));
            setDebitBankNames(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, [open]);

    useEffect(() => {
        if (open?.bankTransactionId) {
            const bankDetails = { ...open };
            bankDetails.bankId = bankDetails.bankTransactionId?.bankId;
            setDetails(bankDetails);
            formik.resetForm();
        }
    }, [open]);

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.bankTransactionId ? 'Update Transaction' : 'Add Transaction'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            {!open.bankTransactionId && (
                                <>
                                    <Grid item xs={12}>
                                        {id ? (
                                            <TextField
                                                disabled={formik.values.bankId || true}
                                                fullWidth
                                                id="bankId"
                                                name="bankId"
                                                label="Bank"
                                                value={formik.values.bankId?.label || null}
                                            />
                                        ) : (
                                            <Autocomplete
                                                id="bankId"
                                                className="bankId-select"
                                                name="bankId"
                                                options={bankNames?.length ? bankNames : []}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('bankId', value);
                                                }}
                                                // onChange={formik.handleChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e, value) => {
                                                            formik.setFieldValue('bankId', value);
                                                        }}
                                                        label="Bank Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="payMethod"
                                            className="payMethod-select"
                                            name="payMethod"
                                            value={formik.values.payMethod}
                                            options={['UPI', 'IMPS']}
                                            onChange={(e, value) => {
                                                formik.setFieldValue('payMethod', value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={(e, value) => {
                                                        console.log(value, e);
                                                        formik.setFieldValue('payMethod', value);
                                                    }}
                                                    label="Select Method"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="transactionUTR"
                                            name="transactionUTR"
                                            label="Bank UTR"
                                            onChange={(e) => {
                                                if (/^\S+$/.test(e.target.value) || !e.target.value) {
                                                    formik.setFieldValue('transactionUTR', e.target.value);
                                                }
                                            }}
                                            value={formik.values.transactionUTR}
                                            error={Boolean(formik.errors.transactionUTR)}
                                            helperText={formik.errors.transactionUTR}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="amount"
                                            name="amount"
                                            label="Amount"
                                            value={formik.values.amount}
                                            // onChange={formik.handleChange}
                                            onChange={(e) => {
                                                if (AMOUNT_REGEX.test(e.target.value) || e.target.value === '') {
                                                    formik.setFieldValue('amount', e.target.value);
                                                } else {
                                                    formik.setFieldValue('amount', formik.values.amount || '');
                                                }
                                            }}
                                            error={Boolean(formik.errors.amount)}
                                            helperText={formik.errors.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="agentRemark"
                                            name="agentRemark"
                                            label="Remark"
                                            onChange={formik.handleChange}
                                            value={formik.values.agentRemark}
                                            error={Boolean(formik.errors.agentRemark)}
                                            helperText={formik.errors.agentRemark}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <TextField
                                            fullWidth
                                            id="mode"
                                            select
                                            name="mode"
                                            label="Select Mode"
                                            value={formik.values.mode}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.mode)}
                                            helperText={formik.errors.mode}
                                        >
                                            <MenuItem value="CR">CR</MenuItem>
                                            <MenuItem value="DR">DR</MenuItem>
                                        </TextField> */}
                                        {formik.values.mode && (
                                            <Autocomplete
                                                id="mode"
                                                className="mode-select"
                                                name="mode"
                                                value={formik.values.mode}
                                                options={['CR', 'DR']}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('mode', value);
                                                }}
                                                // onChange={formik.handleChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e, value) => {
                                                            console.log(value, e);
                                                            formik.setFieldValue('mode', value);
                                                        }}
                                                        label="Mode"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                    {formik.values.mode === 'DR' && (
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                id="debitBankId"
                                                className="debitBankId-select"
                                                name="debitBankId"
                                                options={debitBankNames?.length ? debitBankNames : []}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('debitBankId', value);
                                                }}
                                                // value={formik.values.debitBankId}
                                                // onChange={formik.handleChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(e, value) => {
                                                            console.log(value, e);
                                                            formik.setFieldValue('debitBankId', value);
                                                        }}
                                                        label="Debit Bank Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                            {open?.bankTransactionId && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="amount"
                                            name="amount"
                                            label="Amount"
                                            value={formik.values.amount}
                                            onChange={(e) => {
                                                if (AMOUNT_REGEX.test(e.target.value) || e.target.value === '') {
                                                    formik.setFieldValue('amount', e.target.value);
                                                } else {
                                                    formik.setFieldValue('amount', formik.values.amount || '');
                                                }
                                            }}
                                            error={Boolean(formik.errors.amount)}
                                            helperText={formik.errors.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="transactionUTR"
                                            name="transactionUTR"
                                            label="Bank UTR"
                                            // onWheel={() => document.activeElement.blur()}
                                            // type="number"
                                            onChange={(e) => {
                                                // if (formik.values.payMethod === 'UPI') {
                                                //     if (
                                                //         (NO_REGEX.test(e.target.value) && e.target.value?.length <= 12) ||
                                                //         e.target.value === ''
                                                //     ) {
                                                //         formik.setFieldValue('transactionUTR', e.target.value || '');
                                                //     } else {
                                                //         formik.setFieldValue('transactionUTR', formik.values.transactionUTR || '');
                                                //     }
                                                // } else {
                                                if (/^\S+$/.test(e.target.value) || !e.target.value) {
                                                    formik.setFieldValue('transactionUTR', e.target.value);
                                                }

                                                // }
                                            }}
                                            value={formik.values.transactionUTR}
                                            error={Boolean(formik.errors.transactionUTR)}
                                            helperText={formik.errors.transactionUTR}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading || !formik.values.bankId} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({ mode: 'CR' });
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddTransaction.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddTransaction;

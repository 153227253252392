import * as React from 'react';

// material-ui
import { Button, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Tabs } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch } from 'store';

import axiosServices from 'utils/axios';
import { fetchUsersPassword } from 'utils/api';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import ConfirmationModal from 'ui-component/Modal';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';
import { ROLE_SUPERADMIN } from 'constant';
import useAuth from 'hooks/useAuth';
import { Box } from '@mui/system';

// table header options
const headCells = [
    {
        id: 'userName',
        numeric: false,
        label: 'Username',
        align: 'center'
    },
    {
        id: 'role',
        numeric: false,
        label: 'Role',
        align: 'left'
    },
    {
        id: 'isForceUpdated',
        numeric: false,
        label: 'Force Updated',
        align: 'left'
    }
];

// ==============================|| USER LIST ||============================== //

const UpdatePassword = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [type, setType] = React.useState(0);

    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const fetchUsersCall = async (type) => {
        setLoading(true);
        try {
            const result = await fetchUsersPassword(type ? 'USER' : 'MERCHANT');
            setLoading(false);
            setAllRows(result);
            setRows(result);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchUsersCall(type);
    }, [type]);

    const handleCloseDialog = () => {
        setOpen(false);
        fetchUsersCall(type);
    };

    const handleResetPassword = () => {
        setLoading(true);
        axiosServices
            .put(`forceUpdateUser`, {
                id: open.userId,
                isForceUpdated: true
            })
            .then((r) => {
                setLoading(false);
                setOpen(false);
                fetchUsersCall(type);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: r.data?.message || 'Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while doing action',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const handleResetPasswordAll = () => {
        setLoading(true);
        axiosServices
            .put(`forceUpdateAllUser`, {
                userType: type === 0 ? 'MERCHANT' : 'USER',
                isForceUpdated: true
            })
            .then((r) => {
                setLoading(false);
                setOpen(false);
                fetchUsersCall(type);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: r.data?.message || 'Success',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while doing action',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }

    return (
        <MainCard title="Force Update Password" content={false}>
            {loading && <Loader />}
            <ConfirmationModal
                loading={loading}
                open={open}
                title="Reset Password"
                subTitle="Are you sure, you want to force update password"
                handleClose={handleCloseDialog}
                handleSubmit={() => (open === 'all' ? handleResetPasswordAll() : handleResetPassword())}
            />

            <Box sx={{ width: '100%', marginBottom: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tabs onChange={(e, a) => setType(a)} value={type} aria-label="basic tabs example">
                            <Tab label="MERCHANT" {...a11yProps('MERCHANT')} />
                            <Tab label="USER" {...a11yProps('USER')} />
                        </Tabs>
                        <Button onClick={() => setOpen('all')} style={{ marginRight: 6 }} variant="contained" primary>{`All ${
                            type === 0 ? 'Merchant' : 'User'
                        }`}</Button>
                    </div>
                </Box>
            </Box>

            {user?.role === ROLE_SUPERADMIN ? (
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <TableHeader columns={headCells} />
                        <TableBody>
                            {rows.map((row, index) => {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;

                                return (
                                    <TableRow hover>
                                        <TableCell sx={{ height: 45 }} align="center">
                                            {row.userName}
                                        </TableCell>
                                        <TableCell>{row.role}</TableCell>
                                        <TableCell
                                            onClick={() => {
                                                if (!row.isForceUpdated) {
                                                    setOpen(row);
                                                } else {
                                                    dispatch(
                                                        openSnackbar({
                                                            open: true,
                                                            message: 'Already force updated',
                                                            variant: 'alert',
                                                            alert: {
                                                                color: 'error'
                                                            },
                                                            close: true
                                                        })
                                                    );
                                                    dispatch(closeSnackbar);
                                                }
                                            }}
                                            style={{ fontWeight: 'bold', color: '#38b6fd', cursor: 'pointer' }}
                                        >
                                            {row.isForceUpdated ? 'Yes' : 'No'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
        </MainCard>
    );
};

export default UpdatePassword;

import React from 'react';

// material-ui
import {
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    TextField,
    IconButton,
    Tooltip,
    Autocomplete,
    Button
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import {
    fetchAffiliateSettlementRequests,
    fetchAffiliateProcessingSettlementRequests,
    fetchActiveMerchants,
    fetchMerchantAffiliates
} from 'utils/api';
// import { useDispatch } from 'react-redux';
// import { Delete, Edit } from '@mui/icons-material';
// import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
// import ConfirmationModal from 'ui-component/Modal';
// import axiosServices from 'utils/axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import useAuth from 'hooks/useAuth';
import Chip from 'ui-component/extended/Chip';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import SettlementAction from './SettlementAction';
import { Check, CheckRounded, CloseRounded, Edit, RefreshOutlined, RemoveRedEye } from '@mui/icons-material';
import axiosServices from 'utils/axios';
import ConfirmationModal from 'ui-component/Modal';
import { useNavigate } from 'react-router-dom';
import { ISTDate } from 'constant';
import useQuery from 'hooks/useQuery';
import axiosServicesNew from 'utils/axios_new';

// table header options
const headCells = [
    {
        id: 'invoice',
        numeric: false,
        label: 'Invoice',
        align: 'center'
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'affiliateId',
        numeric: false,
        label: 'Affiliate',
        align: 'left'
    },
    {
        id: 'merchantId',
        numeric: false,
        label: 'Merchant',
        align: 'left'
    },
    {
        id: 'type',
        numeric: true,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Req Amount',
        align: 'left'
    },
    {
        id: 'amount1',
        numeric: true,
        label: 'Paid Amount',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    },
    {
        id: 'Action',
        numeric: false,
        label: 'Action',
        align: 'center'
    },

    {
        id: 'referenceSS',
        numeric: false,
        label: 'Attachment',
        align: 'left'
    },
    {
        id: 'remark',
        numeric: false,
        label: 'Remark',
        align: 'left'
    },
    {
        id: 'id',
        numeric: false,
        label: 'Ref Id',
        align: 'left'
    }
];

const currentDate = ISTDate;

const AffiliateSettlement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const query = useQuery();

    const processing = query.get('processing');

    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const { user } = useAuth();
    const [rejectReq, setRejectReq] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const [merchants, setMerchants] = React.useState([]);
    const [merchant, setMerchant] = React.useState();
    const [status, setStatus] = React.useState();

    const fetchDataFromAPI = async (page, rowsPerPage, startDate, endDate, merchant, status) => {
        setLoading(true);
        try {
            const payload = {
                affiliateId: merchant ? [merchant?.id] : [],
                status: status ? [status] : [],
                startDate,
                endDate
            };
            const result = processing
                ? await fetchAffiliateProcessingSettlementRequests()
                : await fetchAffiliateSettlementRequests(page, rowsPerPage, payload);
            setLoading(false);
            setAllData(result?.data);
            setRows(processing ? result?.data?.SettlementList || [] : result?.data?.transactionList || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await fetchMerchantAffiliates();
            result = result?.map((r) => ({
                id: r.affiliateId || '',
                label: r.username || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage, startDate, endDate, merchant, status);
    }, [page, rowsPerPage, startDate, endDate, merchant, status]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage, startDate, endDate);
    };

    const handleReject = () => {
        setLoading(true);

        axiosServices
            .put(`settle/affiliate/update/${rejectReq?.settlementId}`)
            .then((res) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Request Rejected',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                dispatch(closeSnackbar);
                setRejectReq(false);
                fetchDataFromAPI(page, rowsPerPage, startDate, endDate);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while rejecting request',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const onRefresh = () => {
        setRows([]);
        fetchDataFromAPI(page, rowsPerPage, startDate, endDate, merchant, status);
    };

    const handleGenerateReport = () => {
        setLoading(true);
        const date1 = new Date(startDate).getTime();
        const date2 = new Date(endDate).getTime();
        if (date1 <= date2) {
            const payload = {
                affiliateId: merchant ? [merchant?.id] : [],
                status: status ? [status] : [],
                startDate,
                endDate
            };
            axiosServicesNew
                .post(`newAffiliateSettlementReport`, payload)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'End date cannot be less than Start date',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div> Affiliate Settlement Requests {processing ? '(Processing) ' : ''}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Refresh">
                            <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                        {!processing && (
                            <Button size="small" variant="outlined" sx={{ ml: 2 }} onClick={handleGenerateReport}>
                                Generate Report
                            </Button>
                        )}
                    </div>
                </div>
            }
            content={false}
        >
            {loading && <Loader />}
            <CardContent>
                {processing ? (
                    <></>
                ) : (
                    <Grid container sx={{ mt: 0 }} justifyContent="start" alignItems="center" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate}
                                minDate={startDate}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                                label="End Date"
                            />
                            <Autocomplete
                                id="merchantId"
                                className="merchantId-select"
                                name="merchantId"
                                options={merchants?.length ? merchants : []}
                                onChange={(e, value) => {
                                    setMerchant(value);
                                }}
                                style={{ marginRight: 10 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e, value) => {
                                            setMerchant(value);
                                        }}
                                        size="small"
                                        style={{ width: 200 }}
                                        label="Affiliate Name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                            <Autocomplete
                                id="status"
                                className="status-select"
                                name="status"
                                options={['SUCCESS', 'REJECTED', 'PENDING']}
                                onChange={(e, value) => {
                                    setStatus(value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={(e, value) => {
                                            setStatus(value);
                                        }}
                                        size="small"
                                        style={{ width: 200 }}
                                        label="Status"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
            </CardContent>
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            let variant = 'info';

                            if (row.status === 'SUCCESS') {
                                variant = 'success';
                            } else if (row.status === 'REJECTED') {
                                variant = 'error';
                            } else if (row.status === 'PROCESSING') {
                                variant = 'warning';
                            } else {
                                variant = 'info';
                            }
                            return (
                                <TableRow hover>
                                    <TableCell align="center">
                                        <div style={{ width: 80 }}>{row.settlementId}</div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{ width: 200 }}>{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 150 }}>{row.affiliateName}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 150 }}>{row.merchantName}</div>
                                    </TableCell>
                                    <TableCell sx={{ width: 100 }}>
                                        <div style={{ width: 100 }}>{row.conversionType}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 120 }}>Rs. {row.amount || 0}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 120 }}>Rs. {row.settledAmount || 0}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 80 }}>
                                            <Chip chipcolor={variant} label={row.status} />
                                        </div>
                                    </TableCell>

                                    <TableCell align="center">
                                        <div style={{ width: 100 }}>
                                            {row.status === 'SUCCESS' ? (
                                                <Tooltip placement="top" title="View Invoice">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <RemoveRedEye
                                                            // onClick={() => setOpen(row)}
                                                            onClick={() =>
                                                                navigate(`/invoice/affiliate/${row.settlementId}/${row.affiliateId}`)
                                                            }
                                                            sx={{ fontSize: '1.1rem' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : row.status === 'PROCESSING' ? (
                                                <>
                                                    <Tooltip placement="top" title="Approve">
                                                        <IconButton color="success" aria-label="apprive" size="large">
                                                            <CheckRounded onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Reject">
                                                        <IconButton color="primary" aria-label="reject" size="large">
                                                            <CloseRounded
                                                                onClick={() => setRejectReq(row)}
                                                                sx={{ fontSize: '1.1rem', color: 'red' }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        {row.status === 'PROCESSING' && (
                                            <>
                                                <Tooltip placement="top" title="Approve">
                                                    <IconButton color="success" aria-label="apprive" size="large">
                                                        <CheckRounded onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip placement="top" title="Reject">
                                                    <IconButton color="primary" aria-label="reject" size="large">
                                                        <CloseRounded
                                                            onClick={() => setRejectReq(row)}
                                                            sx={{ fontSize: '1.1rem', color: 'red' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                    </TableCell> */}

                                    <TableCell>
                                        <div style={{ width: 120 }}>
                                            {row?.referenceSS ? (
                                                <a target="_blank" rel="noreferrer" href={row.referenceSS} alt="attachment">
                                                    Attachment
                                                </a>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 120 }}>{row.remark}</div>
                                    </TableCell>
                                    <TableCell title={row.referenceNumber} component="th" id={labelId} scope="row">
                                        <div style={{ width: 120 }}>{row.referenceNumber?.slice(0, 50)}...</div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <SettlementAction open={open} handleCloseDialog={handleCloseDialog} />

            <ConfirmationModal
                open={rejectReq}
                title="Reject Request"
                subTitle="Are you sure, you want to reject selected request"
                handleClose={() => setRejectReq(false)}
                handleSubmit={handleReject}
                loading={loading}
            />

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default AffiliateSettlement;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| BANK ADD DIALOG ||============================== //

const UpdateClaim = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const claimAction = () => {
        if (open?.bankTransactionId) {
            const payload = {
                bankTransactionId: open?.bankTransactionId?.bankTransactionId,
                bankId: open?.bankTransactionId?.bankId
            };
            setLoading(true);
            axiosServices
                .post('/update/transaction/tranval', payload)
                .then((res) => {
                    setLoading(false);
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res.data?.message || 'Saved Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while updating details',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <>
                    {loading && <Loader />}

                    <DialogTitle>Claim the transaction</DialogTitle>
                    <DialogContent>Are you sure you want to claim the unclaim transaction</DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} onClick={claimAction} variant="contained">
                                Claim
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

UpdateClaim.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default UpdateClaim;

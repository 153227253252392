import * as React from 'react';

// material-ui
import {
    Button,
    CardContent,
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';

import { fetchSsTransactionsById, fetchRemarks } from 'utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import Chip from 'ui-component/extended/Chip';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import axiosServices from 'utils/axios';
import * as yup from 'yup';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Loader from 'ui-component/Loader';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyAll } from '@mui/icons-material';

// table header options
const headCells = [
    {
        id: 'transactionId',
        numeric: false,
        label: 'Transaction ID',
        align: 'left'
    },
    {
        id: 'customerName',
        numeric: false,
        label: 'Customer',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Amount',
        align: 'left'
    },
    // {
    //     id: 'payMode',
    //     numeric: true,
    //     label: 'Pay Mode',
    //     align: 'left'
    // },
    {
        id: 'transactionStatus',
        numeric: true,
        label: 'Status',
        align: 'left'
    },
    {
        id: 'customerUTRNumber',
        numeric: true,
        label: 'Customer UTR',
        align: 'left'
    },
    {
        id: 'agentUTRNumber',
        numeric: true,
        label: 'Agent UTR',
        align: 'left'
    }
];

// ==============================|| TXN DETAILS ||============================== //

const SsTransactionDetails = () => {
    const { txnId, id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const [txndetails, setTxnDetails] = React.useState('');
    const [details, setDetails] = React.useState({});
    const [remarks, setRemarks] = React.useState([]);
    const [remarksRes, setRemarksRes] = React.useState();

    const [copy, setCopy] = React.useState(false);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const validationSchema = yup.object({
        utrnumber: yup.string().required('Required Field').nullable(),
        amount: yup.string().required('Required Field'),
        remarks: yup.string().required('Required Field')
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);
            axiosServices
                .put('updateSSTxnStatus', {
                    ...data,
                    merchantId: txndetails.merchantId,
                    txnId: txndetails.transactionId,
                    paymentId: txndetails.id
                })
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    setDetails({});
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Remark Added!',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    dispatch(closeSnackbar);
                    navigate('/payment/ss-transaction');
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    const fetchDataFromAPI = async (id, payId) => {
        try {
            const result = await fetchSsTransactionsById(id, payId);
            setTxnDetails(result?.data);
            setDetails({
                amount: result?.data?.amount,
                utrnumber: result?.data?.customerUTRNumber
            });
            formik.setFieldValue('utrnumber', result?.data?.customerUTRNumber);
            formik.setFieldError('utrnumber', false);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchRemarksFromAPI = async () => {
        try {
            const result = await fetchRemarks();
            if (result?.data) {
                setRemarksRes(result?.data);
                setRemarks(Object.keys(result?.data));
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (txnId && id) {
            fetchDataFromAPI(txnId, id);
            fetchRemarksFromAPI();
        }
    }, [txnId, id]);

    return (
        <>
            <MainCard title="Transaction Detail" content={false}>
                <TableContainer sx={{ mt: 2 }}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.align}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow hover>
                                <TableCell>
                                    <div>{txndetails.transactionId}</div>
                                </TableCell>
                                <TableCell>
                                    <div>Name: {txndetails.customerName}</div>
                                    <div>Email: {txndetails.customerEmail}</div>
                                </TableCell>
                                <TableCell>
                                    <div>Rs. {txndetails.amount || '-'}</div>
                                </TableCell>
                                <TableCell>
                                    <Chip label={txndetails.transactionStatus} />
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex' }}>
                                        <div>{txndetails.customerUTRNumber}</div>
                                        {txndetails.customerUTRNumber && (
                                            <CopyToClipboard onCopy={() => setCopy(true)} text={txndetails.customerUTRNumber}>
                                                <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                            </CopyToClipboard>
                                        )}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex' }}>
                                        <div> {txndetails.agentUTRNumber || ''}</div>
                                        {txndetails.agentUTRNumbe && (
                                            <CopyToClipboard onCopy={() => setCopy(true)} text={txndetails.agentUTRNumber}>
                                                <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                            </CopyToClipboard>
                                        )}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </MainCard>

            {txndetails.transactionStatus !== 'FAILED' && txndetails.transactionStatus !== 'SUCCESS' && (
                <MainCard style={{ marginTop: 10 }} title="Remark" content={false}>
                    {loading && <Loader />}

                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="utrnumber"
                                        name="utrnumber"
                                        onWheel={() => document.activeElement.blur()}
                                        label="UTR"
                                        value={formik.values.utrnumber || ''}
                                        onChange={(e) => {
                                            if (e.target.value?.length <= 12) {
                                                formik.setFieldValue('utrnumber', e.target.value);
                                            }
                                        }}
                                        error={Boolean(formik.errors.utrnumber)}
                                        helperText={formik.errors.utrnumber}
                                    />
                                </Grid>
                                <Grid item xs={8} />

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        label="Amount"
                                        onWheel={() => document.activeElement.blur()}
                                        value={formik.values.amount || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.amount)}
                                        helperText={formik.errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={8} />

                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        select
                                        id="remarks"
                                        name="remarks"
                                        label="Remark"
                                        value={formik.values?.remarks}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors?.remarks)}
                                        helperText={formik.errors?.remarks}
                                    >
                                        {remarks?.map((r) => (
                                            <MenuItem key={r} value={r}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={8} sx={{ mb: 10 }} />
                            </Grid>

                            {formik.values.remarks && (
                                <Button disabled={loading} type="submit" variant="contained">
                                    {remarksRes[formik.values.remarks] === 'SUCCESS' ? 'Accept' : 'Denied'}
                                </Button>
                            )}
                        </form>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

export default SsTransactionDetails;

/* eslint-disable */

// material-ui
import { CardContent, Card, Grid, TextField, Autocomplete } from '@mui/material';

// project imports

import HoverDataCard from 'ui-component/cards/HoverDataCard';
import CurrencyRupee from '@mui/icons-material/CurrencyRupee';
import { fetchPayoutBalance, fetchPayoutSummary1, fetchPayoutMappingAccounts, fetchPG } from 'utils/api';
import React, { useState } from 'react';
import Loader from 'ui-component/Loader';
import MainCard from 'ui-component/cards/MainCard';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ISTDate } from 'constant';
import axiosServices from 'utils/axios';

const currentDate = ISTDate;

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Payout1Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [payoutSummaryAccounts, setPayoutSummaryAccounts] = useState();

    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState();
    const [summaryData, setSummaryData] = useState([]);
    const [endDate, setEndDate] = React.useState(currentDate);
    const [startDate, setStartDate] = React.useState(currentDate);

    const [merchants1, setMerchants1] = useState([]);
    const [selectedMerchant1, setSelectedMerchant1] = useState();
    const [summaryData1, setSummaryData1] = useState([]);
    const [endDate1, setEndDate1] = React.useState(currentDate);
    const [startDate1, setStartDate1] = React.useState(currentDate);

    const [merchants2, setMerchants2] = useState([]);
    const [selectedMerchant2, setSelectedMerchant2] = useState();
    const [summaryData2, setSummaryData2] = useState([]);
    const [endDate2, setEndDate2] = React.useState(currentDate);
    const [startDate2, setStartDate2] = React.useState(currentDate);

    const [merchants3, setMerchants3] = useState([]);
    const [selectedMerchant3, setSelectedMerchant3] = useState();
    const [summaryData3, setSummaryData3] = useState([]);
    const [endDate3, setEndDate3] = React.useState(currentDate);
    const [startDate3, setStartDate3] = React.useState(currentDate);

    const [merchants4, setMerchants4] = useState([]);
    const [selectedMerchant4, setSelectedMerchant4] = useState();
    const [summaryData4, setSummaryData4] = useState([]);
    const [endDate4, setEndDate4] = React.useState(currentDate);
    const [startDate4, setStartDate4] = React.useState(currentDate);

    const [merchants5, setMerchants5] = useState([]);
    const [selectedMerchant5, setSelectedMerchant5] = useState();
    const [summaryData5, setSummaryData5] = useState([]);
    const [endDate5, setEndDate5] = React.useState(currentDate);
    const [startDate5, setStartDate5] = React.useState(currentDate);

    const [merchants6, setMerchants6] = useState([]);
    const [selectedMerchant6, setSelectedMerchant6] = useState();
    const [summaryData6, setSummaryData6] = useState([]);
    const [endDate6, setEndDate6] = React.useState(currentDate);
    const [startDate6, setStartDate6] = React.useState(currentDate);

    const [merchants7, setMerchants7] = useState([]);
    const [selectedMerchant7, setSelectedMerchant7] = useState();
    const [summaryData7, setSummaryData7] = useState([]);
    const [endDate7, setEndDate7] = React.useState(currentDate);
    const [startDate7, setStartDate7] = React.useState(currentDate);

    const getPayoutMappingAccounts = async () => {
        setLoading(true);
        try {
            const result = await fetchPayoutMappingAccounts();
            setLoading(false);
            setPayoutSummaryAccounts(result?.data || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        getPayoutMappingAccounts();
    }, []);

    React.useEffect(() => {
        setLoading(true);
        setMerchants([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/CLICKPAY?startDate=${startDate}&endDate=${endDate}&merchantId=${selectedMerchant?.id || null}`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate, endDate, selectedMerchant]);

    // React.useEffect(() => {
    //     setLoading(true);
    //     setMerchants5([]);
    //     axiosServices
    //         .get(
    //             `/metaDetailsWithPgLabelName/SAFEXPAY?startDate=${startDate5}&endDate=${endDate5}&merchantId=${
    //                 selectedMerchant5?.id || null
    //             }`
    //         )
    //         .then((res) => {
    //             setLoading(false);
    //             setSummaryData5(res.data?.data || []);
    //         })
    //         .catch((e) => {
    //             console.log(e.message);
    //             setLoading(false);
    //         });
    // }, [startDate5, endDate5, selectedMerchant5]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants7([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/SAFEXPAY_WL?startDate=${startDate7}&endDate=${endDate7}&merchantId=${
                    selectedMerchant7?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData7(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate7, endDate7, selectedMerchant7]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants6([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/ZWITCHPAY?startDate=${startDate6}&endDate=${endDate6}&merchantId=${
                    selectedMerchant6?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData6(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate6, endDate6, selectedMerchant6]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants4([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/INDIAPAY?startDate=${startDate4}&endDate=${endDate4}&merchantId=${
                    selectedMerchant4?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData4(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate4, endDate4, selectedMerchant4]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants3([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/PAYFAST?startDate=${startDate3}&endDate=${endDate3}&merchantId=${
                    selectedMerchant3?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData3(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate3, endDate3, selectedMerchant3]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants1([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/LINESPAY?startDate=${startDate1}&endDate=${endDate1}&merchantId=${
                    selectedMerchant1?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData1(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate1, endDate1, selectedMerchant1]);

    React.useEffect(() => {
        setLoading(true);
        setMerchants2([]);
        axiosServices
            .get(
                `/metaDetailsWithPgLabelName/PAISAORDER?startDate=${startDate2}&endDate=${endDate2}&merchantId=${
                    selectedMerchant2?.id || null
                }`
            )
            .then((res) => {
                setLoading(false);
                setSummaryData2(res.data?.data || []);
            })
            .catch((e) => {
                console.log(e.message);
                setLoading(false);
            });
    }, [startDate2, endDate2, selectedMerchant2]);

    React.useEffect(() => {
        if (summaryData?.length && payoutSummaryAccounts) {
            setMerchants(
                payoutSummaryAccounts[summaryData?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData1?.length && payoutSummaryAccounts) {
            setMerchants1(
                payoutSummaryAccounts[summaryData1?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData1, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData2?.length && payoutSummaryAccounts) {
            setMerchants2(
                payoutSummaryAccounts[summaryData2?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData2, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData3?.length && payoutSummaryAccounts) {
            setMerchants3(
                payoutSummaryAccounts[summaryData3?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData3, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData4?.length && payoutSummaryAccounts) {
            setMerchants4(
                payoutSummaryAccounts[summaryData4?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData4, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData5?.length && payoutSummaryAccounts) {
            setMerchants5(
                payoutSummaryAccounts[summaryData5?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData5, payoutSummaryAccounts]);

    React.useEffect(() => {
        if (summaryData7?.length && payoutSummaryAccounts) {
            setMerchants7(
                payoutSummaryAccounts[summaryData7?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData7, payoutSummaryAccounts]);

    console.log(merchants7);


    React.useEffect(() => {
        if (summaryData6?.length && payoutSummaryAccounts) {
            setMerchants6(
                payoutSummaryAccounts[summaryData6?.[0]?.account]?.map((r) => {
                    return {
                        id: r.merchantId,
                        name: r.merchantName
                    };
                })
            );
        }
    }, [summaryData6, payoutSummaryAccounts]);

    return (
        <>
            {summaryData?.length ? (
                <MainCard style={{ marginTop: 20 }} title="CLICKPAY" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate}
                                        minDate={startDate}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants?.length ? merchants : []}
                                    getOptionLabel={(e) => e?.name || ''}
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>
                        <div style={{ width: '100%' }}>
                            {summaryData?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {summaryData1?.length ? (
                <MainCard style={{ marginTop: 20 }} title="LINESPAY" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate1}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate1(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate1}
                                        minDate={startDate1}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate1(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants1?.length ? merchants1 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant1(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant1(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>
                        <div style={{ width: '100%' }}>
                            {summaryData1?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {summaryData3?.length ? (
                <MainCard style={{ marginTop: 20 }} title="PAYFAST" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate3}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate3(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate3}
                                        minDate={startDate3}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate3(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants3?.length ? merchants3 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant3(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant3(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>
                        <div style={{ width: '100%' }}>
                            {summaryData3?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {summaryData2?.length ? (
                <MainCard style={{ marginTop: 20 }} title="PAISAORDER" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate2}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate2(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate2}
                                        minDate={startDate2}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate2(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants2?.length ? merchants2 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant2(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant2(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>

                        <div style={{ width: '100%' }}>
                            {summaryData2?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {summaryData4?.length ? (
                <MainCard style={{ marginTop: 20 }} title="INDIAPAY" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate4}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate4(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate4}
                                        minDate={startDate4}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate4(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants4?.length ? merchants4 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant4(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant4(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>

                        <div style={{ width: '100%' }}>
                            {summaryData4?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {/* {summaryData5?.length ? (
                <MainCard style={{ marginTop: 20 }} title="SAFEX" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate5}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate5(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate5}
                                        minDate={startDate5}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate5(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants5?.length ? merchants5 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant5(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant5(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>

                        <div style={{ width: '100%' }}>
                            {summaryData5?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )} */}

            {summaryData7?.length ? (
                <MainCard style={{ marginTop: 20 }} title="SAFEXPAY WL" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate7}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate7(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate7}
                                        minDate={startDate7}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate7(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants7?.length ? merchants7 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant7(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant7(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>

                        <div style={{ width: '100%' }}>
                            {summaryData7?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {summaryData6?.length ? (
                <MainCard style={{ marginTop: 20 }} title="ZWITCHPAY" content={false}>
                    <Grid container spacing={1}>
                        <CardContent>
                            <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={startDate6}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                                            setStartDate6(stDate);
                                        }}
                                        maxDate={ISTDate}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="Start Date"
                                    />
                                    <DatePicker
                                        inputFormat="YYYY/MM/DD"
                                        value={endDate6}
                                        minDate={startDate6}
                                        maxDate={ISTDate}
                                        onChange={(value) => {
                                            const dt = new Date(value);
                                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                                            setEndDate6(enDate);
                                        }}
                                        renderInput={(params) => (
                                            <TextField sx={{ mb: 2, width: 180, mr: '1rem' }} size="small" {...params} />
                                        )}
                                        label="End Date"
                                    />
                                </LocalizationProvider>
                                <Autocomplete
                                    id="merchantId"
                                    className="merchantId-select"
                                    name="merchantId"
                                    options={merchants6?.length ? merchants6 : []}
                                    onChange={(e, value) => {
                                        setSelectedMerchant6(value);
                                    }}
                                    getOptionLabel={(e) => e?.name || ''}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setSelectedMerchant6(value);
                                            }}
                                            size="small"
                                            sx={{ mb: 2, width: 180 }}
                                            label="Merchants"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                        </CardContent>

                        <div style={{ width: '100%' }}>
                            {summaryData6?.map((item) => (
                                <div style={{ margin: '10px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ fontSize: 14, fontWeight: 500, marginLeft: 20, marginTop: 0 }}>
                                            {item.account} | {item.pgLabelName} |{' '}
                                            <span style={{ color: '#39b4fd' }}>₹ {item.balance || 0}</span>
                                        </p>
                                    </div>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    title={`Total`}
                                                    primary={`${
                                                        item.transactionCounts?.Success +
                                                        item.transactionCounts?.Pending +
                                                        item.transactionCounts?.Failed
                                                    }`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`SUCCESS (${item.transactionCounts?.Success || 0})`}
                                                    primary={`${item.transactionCounts?.SuccessAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`PENDING (${item.transactionCounts?.Pending || 0})`}
                                                    primary={`${item.transactionCounts?.PendingAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={3} sm={3}>
                                            <Card style={{ border: '1px solid lightgray', margin: '0px 5px 0px 20px' }}>
                                                <HoverDataCard
                                                    iconPrimary={CurrencyRupee}
                                                    title={`FAILED (${item.transactionCounts?.Failed || 0})`}
                                                    primary={`${item.transactionCounts?.FailedAmount || 0}`}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </MainCard>
            ) : (
                <div></div>
            )}

            {loading && <Loader />}
        </>
    );
};

export default Payout1Dashboard;

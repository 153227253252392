import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Slide,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { AVAILABLE_METHODS } from 'constant';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanks } from 'store/slices/bank';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { fetchPayinMeta } from 'utils/api';
import axiosServices from 'utils/axios';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// table header options
const headCells = [
    {
        id: 'name',
        label: 'Account',
        align: 'left'
    },
    {
        id: 'account',
        label: 'Account ID',
        align: 'left'
    },
    {
        id: 'pglabel',
        label: 'PG Label',
        align: 'left'
    },
    {
        id: 'mid',
        label: 'MID',
        align: 'left'
    },
    {
        id: 'isActive',
        label: 'Is Active',
        align: 'left'
    }
];

const PayInMeta = ({ id, setRefetch }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const [selectedBank, setSelectedBank] = React.useState(false);
    const [selectedMethod, setSelectedMethod] = React.useState();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchDataFromAPI = async (id) => {
        try {
            const result = await fetchPayinMeta();
            setRows(result);
            setAllRows(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(id);
    }, []);

    React.useEffect(() => {
        if (value) {
            const auto = allRows.filter((r) => r.creationMethod === 'AUTO');
            setRows(auto);
        } else {
            const auto = allRows.filter((r) => r.creationMethod !== 'AUTO');
            setRows(auto);
        }
    }, [value, allRows]);

    const handleAddMeta = () => {
        if (!selectedBank.accountId) return;
        axiosServices
            .post('add/merchant/in/payin', { accountId: selectedBank.accountId, merchantId: id })
            .then((res) => {
                console.log(res);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Meta added successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
                setSelectedBank(false);
                setRefetch(Math.random());
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while adding bank',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    console.log('rows', rows);

    return (
        <div style={{ margin: '10px 20px' }}>
            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                            <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                                    Action
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.accountId}</TableCell>
                                <TableCell>{row.label}</TableCell>
                                <TableCell>{row.pgLabelId}</TableCell>
                                <TableCell>{row.mid}</TableCell>
                                <TableCell>{row.isActive ? 'yes' : 'no'}</TableCell>
                                <TableCell align="center">
                                    <Button onClick={() => setSelectedBank(row)} variant="contained" size="small">
                                        Add Meta
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {rows?.length === 0 && <div style={{ marginTop: 10, color: 'red' }}>No Data</div>}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={selectedBank}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSelectedBank(false)}
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-description1"
            >
                <>
                    <DialogTitle id="alert-dialog-slide-title1">{selectedBank?.accountHolderName}</DialogTitle>
                    <DialogContent style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
                        Are you sure you want to add meta?
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                            onClick={() => setSelectedBank(false)}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" size="small" onClick={handleAddMeta}>
                            Add
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </div>
    );
};

export default PayInMeta;

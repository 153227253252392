import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import { log } from 'util';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddConfig = ({ merchants, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        // configName: 0,
        // requestSalt: 0,
        // responseSalt: 35
    });

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        configName: yup.string().required('Required Field').nullable(),
        mid: yup.string().required('Required Field').nullable(),
        requestSalt: yup.string().required('Required Field').nullable(),
        responseSalt: yup.string().required('Required Field').nullable(),
        requestEncryptKey: yup.string().required('Required Field').nullable(),
        responseDecryptKey: yup.string().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);

            if (open?.configId) {
                axiosServices
                    .post('click/pay/config', { ...data })
                    .then((res) => {
                        setLoading(false);
                        formik.resetForm();
                        setDetails({});
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        resetForm();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                axiosServices
                    .post('click/pay/config', {
                        ...data
                    })
                    .then((res) => {
                        setLoading(false);
                        formik.resetForm();
                        setDetails({});
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.configId) {
            setDetails(open);
        }
    }, [open]);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="xs" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Clickpay Config</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="configName"
                                    name="configName"
                                    label="Config Name"
                                    value={formik.values.configName || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.configName)}
                                    helperText={formik.errors.configName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="mid"
                                    name="mid"
                                    label="MID"
                                    value={formik.values.mid || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.mid)}
                                    helperText={formik.errors.mid}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="requestEncryptKey"
                                    name="requestEncryptKey"
                                    label="Request Encrypt Key"
                                    value={formik.values.requestEncryptKey || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.requestEncryptKey)}
                                    helperText={formik.errors.requestEncryptKey}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="responseDecryptKey"
                                    name="responseDecryptKey"
                                    label="Response Decrypt Key"
                                    value={formik.values.responseDecryptKey || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.responseDecryptKey)}
                                    helperText={formik.errors.responseDecryptKey}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="requestSalt"
                                    name="requestSalt"
                                    label="Request Salt"
                                    value={formik.values.requestSalt || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.requestSalt)}
                                    helperText={formik.errors.requestSalt}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="responseSalt"
                                    name="responseSalt"
                                    label="Response Salt"
                                    value={formik.values.responseSalt || null}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.responseSalt)}
                                    helperText={formik.errors.responseSalt}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddConfig.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddConfig;

import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    Tooltip,
    IconButton,
    TextField,
    MenuItem
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { getActiveBanks, fetchPayInDetailsByID } from 'utils/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Chip from 'ui-component/extended/Chip';
import { Check, CloseOutlined, Edit } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddPayInMeta from './AddPayinMeta';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'account',
        numeric: false,
        label: 'Account',
        align: 'left'
    },
    {
        id: 'label',
        numeric: true,
        label: 'Label',
        align: 'left'
    },

    {
        id: 'isActive',
        numeric: true,
        label: 'Is Active',
        align: 'left'
    },
    {
        id: 'Limit',
        numeric: false,
        label: 'Limit',
        align: 'left'
    }
];

const PayinDetails = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [selectedBank, setSelectedBank] = React.useState();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [field, setField] = React.useState({
        label: '',
        value: ''
    });

    const [editFields, setEditFields] = React.useState({
        active: false,
        id: '',
        minLimit: false,
        maxLimit: false
    });

    const resetEditFields = () => {
        setEditFields({ active: false, id: '' });
        setField({ label: '', value: '' });
    };

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await fetchPayInDetailsByID(id, page, rowsPerPage);
            setLoading(false);
            setAllData(result?.data);
            setRows(result?.data || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await getActiveBanks();
            result = result.map(({ id, bankName, accountHolderName }) => ({
                value: id,
                label: bankName,
                name: accountHolderName
            }));
            setSelectedBank(result.filter((r) => r?.value === id)[0]);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (!page && !rowsPerPage) return;
        fetchDataFromAPI(page, rowsPerPage);
    }, [id, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const handleSave = () => {
        axiosServices
            .put(`updatePayinMetaWithLabel/${id}`, { metaId: editFields.id, label: field.label, value: field.value })
            .then((res) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Field updated successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
                resetEditFields();
                fetchDataFromAPI();
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating field',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard title={<div>{id}</div>} content={false}>
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2} />
                    {/* <div style={{ fontSize: 14, fontWeight: 500 }}>Live Balance: ₹ {allData?.liveBalance || 0}</div>
                    </Grid> */}
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button disabled={loading} variant="contained" onClick={() => setOpen(true)}>
                            Add Payin Meta
                        </Button>
                        <AddPayInMeta id={id} open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer sx={{ mt: 2, height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell component="th" id={labelId} scope="row">
                                        <div style={{ textAlign: 'center', fontWeight: 500 }}>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                        <div style={{ textAlign: 'center', fontWeight: 500 }}>
                                            Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{row.pgLabelId}</div>
                                        <div>{row.accountId}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 100 }}>Label: {row.label}</div>
                                        <div>Email: {row.email}</div>
                                    </TableCell>
                                    <TableCell>
                                        {editFields.active && editFields.id === row.id ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="active"
                                                    size="small"
                                                    name="active"
                                                    label="Status"
                                                    style={{ width: 100, marginRight: 5 }}
                                                    // value={field.active ? 'Yes' : 'No'}
                                                    onChange={(e) => setField({ label: 'is_active', value: e.target.value === 'Yes' })}
                                                >
                                                    {['Yes', 'No']?.map((method) => (
                                                        <MenuItem value={method}>{method}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <Check style={{ color: 'green', fontSize: 24, cursor: 'pointer' }} onClick={handleSave} />
                                                <CloseOutlined
                                                    style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                    onClick={resetEditFields}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                role="presentation"
                                                style={{ color: row.isActive ? 'green' : 'red', cursor: 'pointer' }}
                                                onKeyDown={() => console.log('')}
                                                value={field.value}
                                                onClick={(e) => {
                                                    setEditFields({
                                                        active: true,
                                                        id: row.id
                                                    });
                                                }}
                                            >
                                                {row.isActive ? 'Yes' : 'No'}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell style={{ width: 200 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Min Limit:
                                            {editFields.minLimit && editFields.id === row.id ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Min Limit"
                                                        type="number"
                                                        style={{ width: 100, marginRight: 5 }}
                                                        value={field.value}
                                                        onChange={(e) => setField({ label: 'min_limit', value: e.target.value })}
                                                    />
                                                    <Check
                                                        style={{ color: 'green', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={handleSave}
                                                    />
                                                    <CloseOutlined
                                                        style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={resetEditFields}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="presentation"
                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                    onKeyDown={() => console.log('')}
                                                    onClick={(e) => {
                                                        setEditFields({
                                                            active: false,
                                                            dailyLimit: false,
                                                            maxLimit: false,
                                                            minLimit: true,
                                                            id: row.id
                                                        });
                                                    }}
                                                >
                                                    {row.minLimit || 'NA'}
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Max Limit:
                                            {editFields.maxLimit && editFields.id === row.id ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Max Limit"
                                                        type="number"
                                                        style={{ width: 100, marginRight: 5 }}
                                                        value={field.value}
                                                        onChange={(e) => setField({ label: 'max_limit', value: e.target.value })}
                                                    />
                                                    <Check
                                                        style={{ color: 'green', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={handleSave}
                                                    />
                                                    <CloseOutlined
                                                        style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={resetEditFields}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="presentation"
                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                    onKeyDown={() => console.log('')}
                                                    onClick={(e) => {
                                                        setEditFields({
                                                            active: false,
                                                            minLimit: false,
                                                            maxLimit: true,
                                                            id: row.id
                                                        });
                                                    }}
                                                >
                                                    {row.maxLimit || 'NA'}
                                                </div>
                                            )}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default PayinDetails;

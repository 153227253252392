import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    InputAdornment,
    TextField,
    Fab,
    Tooltip,
    Select,
    MenuItem,
    Autocomplete,
    IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

import SearchIcon from '@mui/icons-material/Search';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchBankTByFilter, fetchBankTransactions, getActiveBanks } from 'utils/api';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Chip from 'ui-component/extended/Chip';
import { AddCircle, Edit, EditAttributes, EditTwoTone, RefreshOutlined } from '@mui/icons-material';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axiosServices from 'utils/axios';
import { ISTDate } from 'constant';
// import UpdateBank from './UpdateBank';
import { IconEditCircle, IconEditCircleOff } from '@tabler/icons';
// import UpdateMode from './UpdateMode';
import UpdateClaim from './UpdateClaim';
import axiosServicesNew from 'utils/axios_new';
import AddTransaction from '../BankTransaction/AddTransaction';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'txnId',
        numeric: false,
        label: 'Txn ID',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'utr',
        numeric: true,
        label: 'UTR',
        align: 'left'
    },
    {
        id: 'is_get',
        numeric: false,
        label: 'Is Get',
        align: 'left'
    },
    {
        id: 'method',
        numeric: true,
        label: 'Pay Method',
        align: 'left'
    },
    {
        id: 'account',
        numeric: true,
        label: 'Account',
        align: 'left'
    },
    {
        id: 'mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'desc',
        numeric: true,
        label: 'Desc',
        align: 'left'
    },
    {
        id: 'txn_date',
        numeric: true,
        label: 'TXN Date',
        align: 'left'
    }
];

const currentDate = ISTDate;

const IsGetBankTransaction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);
    const [bankNames, setBankNames] = React.useState([]);
    const [value, setValue] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState('exactUTR');
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [claim, setClaim] = React.useState(false);

    const [get, setGet] = React.useState('unclaimed');

    const [bank, setBank] = React.useState();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const getBanks = async () => {
        try {
            let result = await getActiveBanks();
            result = result.map(({ bankId, bankName, accountHolderName }) => ({
                value: bankId,
                label: `${accountHolderName} - ${bankName}`
            }));
            setBankNames(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getBanks();
    }, []);

    const fetchDataFromAPI = async (page, rowsPerPage, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await fetchBankTransactions(page, rowsPerPage, startDate, endDate);
            setLoading(false);
            setAllData(result?.data);
            setRows(result?.data?.transactionList || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const fetchBanksTransactions = async (searchObj) => {
        if (searchObj?.selectedValue === 'exactUTR' && !searchObj.value && !bank?.value && !get) {
            fetchDataFromAPI(page, rowsPerPage, startDate, endDate);
        } else {
            try {
                const type = searchObj.selectedValue;
                const value = searchObj.value;
                // if (bank?.value) {
                //     type = 'transactionId';
                //     value = bank?.value;
                // } else if (searchObj.value) {
                //     type = searchObj.selectedValue;
                // }
                const params = {
                    transactionId: type === 'transactionId' ? value : '',
                    transactionUTR: type === 'transactionUTR' ? value : '',
                    exactUTR: type === 'exactUTR' ? value : '',
                    amount: type === 'amount' ? value : '',
                    isGet: get,
                    bankId: bank?.value,
                    startDate,
                    endDate
                };

                const results = await fetchBankTByFilter(params, page, rowsPerPage);
                if (results) {
                    setAllData(results?.data);
                    setRows(results?.data?.transactionList || []);
                }
            } catch (error) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something went wrong',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        }
    };

    React.useEffect(() => {
        if (!page && !rowsPerPage) return;
        fetchBanksTransactions({ value, selectedValue });
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const onSearch = (event) => {
        if (selectedValue === 'utrNo') {
            if (event.target.value?.length <= 12) {
                setValue(event.target.value);
            }
        } else {
            setValue(event.target.value);
        }
    };

    const handleSearch = () => {
        // if (value.length > 0) {
        fetchBanksTransactions({ value, selectedValue });
        // } else {
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: 'Please enter valid search criteria',
        //             variant: 'alert',
        //             alert: {
        //                 color: 'error'
        //             },
        //             close: true
        //         })
        //     );
        // }
    };

    const clearSearch = () => {
        // setSelectedValue('bankId');
        // setValue('');
        // setPage(0);
        // setRowsPerPage(50);
        // fetchDataFromAPI(0, 50, startDate, endDate);
        window.location.reload();
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setClaim(false);
        fetchBanksTransactions({ value, selectedValue });
    };

    const redirectToBank = () => {
        if (bank?.value) {
            navigate(bank.value);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Select bank',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleGenerateReport = () => {
        setLoading(true);
        const date1 = new Date(startDate).getTime();
        const date2 = new Date(endDate).getTime();
        if (date1 <= date2) {
            const type = selectedValue;
            const payload = {
                transactionId: type === 'transactionId' ? value : '',
                transactionUTR: type === 'transactionUTR' ? value : '',
                exactUTR: type === 'exactUTR' ? value : '',
                amount: type === 'amount' ? value : '',
                isGet: get,
                bankId: bank?.value,
                startDate,
                endDate
            };
            axiosServicesNew
                .post(`newBankTxnFilterReport`, payload)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Something went wrong',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'End date cannot be less than Start date',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const onRefresh = () => {
        setRows([]);
        fetchBanksTransactions({ value, selectedValue });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>Unclaimed Bank Transactions</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tooltip title="Refresh">
                            <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer' }} />
                        </Tooltip>

                        <Button size="small" variant="contained" sx={{ mr: '1rem' }} onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                        {/* <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            + Add Txn
                        </Button> */}
                    </div>
                </div>
            }
            content={false}
        >
            {/* {open && <AddTransaction bank="all" sx={{ mr: '1rem', mt: 2 }} open={open} handleCloseDialog={handleCloseDialog} />} */}

            {loading && <Loader />}
            <CardContent>
                <Grid sx={{ ml: 0 }} container justifyContent="start" alignItems="center" spacing={2}>
                    <Select value={selectedValue} onChange={handleChange} sx={{ width: 220, mr: '1rem', mt: 2 }} size="small">
                        <MenuItem value="exactUTR">Exact UTR</MenuItem>
                        <MenuItem value="transactionUTR">UTR</MenuItem>
                        <MenuItem value="transactionId">Transaction ID</MenuItem>
                        <MenuItem value="amount">Amount</MenuItem>
                    </Select>
                    <TextField
                        sx={{ width: 220, mr: '1rem', mt: 2 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        onChange={onSearch}
                        type={selectedValue === 'amount' ? 'number' : 'string'}
                        placeholder="Enter Search Value"
                        value={value}
                        size="small"
                    />

                    {/* <Autocomplete
                        id="isGet"
                        className="isGet-select"
                        name="isGet"
                        options={['YES', 'NO']}
                        onChange={(e, value) => {
                            setGet(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setGet(value);
                                }}
                                size="small"
                                sx={{ width: 220, mr: 2, mt: 2 }}
                                label="IS GET"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    /> */}
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item justifyContent="end" alignItems="center" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid
                                container
                                style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}
                                xs={12}
                                sm={12}
                                md={12}
                                alignItems="center"
                            >
                                <Autocomplete
                                    id="bankId"
                                    className="bankId-select"
                                    name="bankId"
                                    options={bankNames?.length ? bankNames : []}
                                    onChange={(e, value) => {
                                        setBank(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(e, value) => {
                                                setBank(value);
                                            }}
                                            size="small"
                                            sx={{ width: 220, mr: 2, mt: 2 }}
                                            label="Bank Name"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                                <DatePicker
                                    inputFormat="YYYY/MM/DD"
                                    value={startDate}
                                    onChange={(value) => {
                                        const dt = new Date(value);
                                        const stDate = dayjs(dt).format('YYYY-MM-DD');
                                        setStartDate(stDate);
                                    }}
                                    maxDate={ISTDate}
                                    renderInput={(params) => <TextField sx={{ width: 210, mr: 2, mt: 2 }} size="small" {...params} />}
                                    label="Start Date"
                                />
                                <DatePicker
                                    inputFormat="YYYY/MM/DD"
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={ISTDate}
                                    onChange={(value) => {
                                        const dt = new Date(value);
                                        const enDate = dayjs(dt).format('YYYY-MM-DD');
                                        setEndDate(enDate);
                                    }}
                                    renderInput={(params) => <TextField sx={{ width: 210, mr: 2, mt: 2 }} size="small" {...params} />}
                                    label="End Date"
                                />
                                <Button size="small" variant="contained" sx={{ mr: '1rem', ml: '1rem', mt: 2 }} onClick={handleSearch}>
                                    Apply
                                </Button>
                                <Button size="small" variant="contained" sx={{ mr: '1rem', mt: 2 }} color="secondary" onClick={clearSearch}>
                                    Clear
                                </Button>
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </CardContent>
            {/* {open && <AddTransaction bank="all" sx={{ mr: '1rem', mt: 2 }} open={open} handleCloseDialog={handleCloseDialog} />} */}
            <TableContainer sx={{ mt: 0, height: 500 }}>
                <Table stickyHeader aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell align="center" style={{ fontWeight: 500 }} component="th" id={labelId} scope="row">
                                        <div style={{ width: 220 }}>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                        <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 150 }}> {row.transactionId}</div>
                                    </TableCell>
                                    <TableCell style={{ minWidth: 100 }}>{row.amount}</TableCell>
                                    <TableCell style={{ minWidth: 200 }}>
                                        <div>{row.transactionUTR}</div>
                                        <div>{row.agentRemark ? `Remark: ${row.agentRemark}` : ''}</div>
                                    </TableCell>
                                    <TableCell>
                                        {row.isGet === 'unclaimed' ? (
                                            <div
                                                role="presentation"
                                                onKeyDown={() => setClaim(row)}
                                                onClick={() => setClaim(row)}
                                                style={{
                                                    textDecoration: 'underline',
                                                    width: 60,
                                                    fontWeight: 500,
                                                    color: 'blue',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {row.isGet}
                                            </div>
                                        ) : (
                                            <div style={{ width: 60 }}>{row.isGet}</div>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 80 }}>{row.payMethod}</div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ width: 180 }}>{row.accountHolderName}</div>
                                        <div>{row.accountNumber}</div>
                                        <div style={{ fontSize: 10 }}>{row.bankName}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 60 }}>
                                            <Chip chipcolor={row.mode === 'CR' ? 'success' : 'error'} label={row.mode} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>{row.description}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 80 }}>{row.txnDate}</div>
                                    </TableCell>

                                    {/* <TableCell sx={{ display: 'flex' }} align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Change Bank">
                                            <IconButton color="primary" aria-label="edit" size="small">
                                                <IconEditCircle onClick={() => setOpen1(row)} sx={{ fontSize: 12 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Change Mode">
                                            <IconButton color="primary" aria-label="edit" size="small">
                                                <IconEditCircleOff onClick={() => setOpen2(row)} sx={{ fontSize: 12 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* {open1 && <UpdateBank bank="all" sx={{ mr: '1rem', mt: 2 }} open={open1} handleCloseDialog={handleCloseDialog} />}
            {open2 && <UpdateMode bank="all" sx={{ mr: '1rem', mt: 2 }} open={open2} handleCloseDialog={handleCloseDialog} />} */}
            {claim && <UpdateClaim bank="all" sx={{ mr: '1rem', mt: 2 }} open={claim} handleCloseDialog={handleCloseDialog} />}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default IsGetBankTransaction;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

import { fetchPGNames } from 'utils/api';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    pgLabel: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddPG = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        pgLabel: '',
        showPayIn: false,
        showPayout: false
    });

    const [loading, setLoading] = useState(false);
    const [pgNames, setPGNames] = useState([]);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open?.pgLabel) {
                setLoading(true);
                axiosServices
                    .put('updatePgMenu', data)
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while updating details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                setLoading(true);
                axiosServices
                    .post('addPgMenu', data)
                    .then((res) => {
                        setLoading(false);

                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Submit Success',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding merchantid',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    const fetchNames = async () => {
        try {
            const result = await fetchPGNames();
            setPGNames(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchNames();
    }, []);

    useEffect(() => {
        if (open?.pgLabel) {
            setDetails(open);
        }
    }, [open]);

    console.log(formik.values, formik.errors);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.pgLabel ? 'Edit PG' : 'Add PG'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            {!open?.pgLabel && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="pgLabel"
                                        select
                                        name="pgLabel"
                                        label="Select PG Name"
                                        value={formik.values.pgLabel}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.pgLabel)}
                                        helperText={formik.errors.pgLabel}
                                    >
                                        {pgNames?.map((r) => (
                                            <MenuItem key={r} value={r}>
                                                {r}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('showPayIn', e.target.checked);
                                            }}
                                            checked={formik.values.showPayIn}
                                        />
                                    }
                                    label="Show PayIn"
                                />
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('showPayout', e.target.checked);
                                            }}
                                            checked={formik.values.showPayout}
                                        />
                                    }
                                    label="Show PayOut"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddPG.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddPG;

import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    TextField,
    InputAdornment,
    MenuItem
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import SearchIcon from '@mui/icons-material/Search';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchPayoutDetailsByPG } from 'utils/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Chip from 'ui-component/extended/Chip';
import { Check, CloseOutlined, Edit } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddPayoutMeta from './AddPayoutMeta';
import axiosServices from 'utils/axios';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';

// table header options
const headCells = [
    {
        id: 'id',
        numeric: true,
        label: 'ID',
        align: 'center'
    },
    {
        id: 'account',
        numeric: false,
        label: 'Account',
        align: 'left'
    },
    {
        id: 'mid',
        numeric: true,
        label: 'mid',
        align: 'left'
    },
    {
        id: 'Email',
        numeric: true,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'proxy',
        numeric: false,
        label: 'Proxy',
        align: 'left'
    },
    {
        id: 'isActive',
        numeric: true,
        label: 'Active',
        align: 'left'
    },
    {
        id: 'limit',
        numeric: true,
        label: 'Limit',
        align: 'left'
    }
];

const PayoutDetails = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [search, setSearch] = React.useState('');

    const [field, setField] = React.useState({
        label: '',
        value: ''
    });

    const [editFields, setEditFields] = React.useState({
        active: false,
        id: '',
        minLimit: false,
        maxLimit: false
    });

    const resetEditFields = () => {
        setEditFields({ active: false, id: '' });
        setField({ label: '', value: '' });
    };

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchPayoutDetailsByPG(id);
            setLoading(false);
            setAllData(result?.data || []);
            setRows(result?.data || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI();
    }, [id, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI();
    };

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');

        if (newString) {
            const newRows = rows.filter((row) => {
                let matches = true;

                const properties = ['email', 'mid', 'agId', 'minLimit', 'maxLimit', 'mid', 'account', 'ammount'];
                let containsQuery = false;

                properties?.forEach((property) => {
                    if (row[property]?.toString()?.toLowerCase()?.includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setRows(newRows);
        } else {
            setRows(allData);
        }
    };

    const handleSave = () => {
        axiosServices
            .put(`updatePayoutMetaWithLabel/${id}`, { metaId: editFields.id, label: field.label, value: field.value })
            .then((res) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Field updated successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
                resetEditFields();
                fetchDataFromAPI();
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating field',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard title={<div>{id}</div>} content={false}>
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search"
                            value={search}
                            size="small"
                        />
                    </Grid>
                    {/* <div style={{ fontSize: 14, fontWeight: 500 }}>Live Balance: ₹ {allData?.liveBalance || 0}</div>
                    </Grid> */}
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button disabled={loading} variant="contained" onClick={() => setOpen(true)}>
                            Add Payout Meta
                        </Button>
                        <AddPayoutMeta id={id} open={open} handleCloseDialog={handleCloseDialog} />
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer sx={{ mt: 2, height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell align="center" component="th" id={labelId} scope="row">
                                        <div style={{ width: 40, fontWeight: 500, textAlign: 'center' }}>{row.id}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 100 }}>{row.account}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 200 }}>MID {row.mid}</div>
                                        <div>Balance {row.amount}</div>
                                        <div>Updated {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 100 }}>{row.email}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 100 }}>Proxy ID {row.proxyId}</div>
                                        <div>Proxy IP {row.proxyIp}</div>
                                    </TableCell>

                                    <TableCell>
                                        {editFields.active && editFields.id === row.id ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    id="active"
                                                    size="small"
                                                    name="active"
                                                    label="Status"
                                                    style={{ width: 100, marginRight: 5 }}
                                                    // value={field.active ? 'Yes' : 'No'}
                                                    onChange={(e) => setField({ label: 'is_active', value: e.target.value === 'Yes' })}
                                                >
                                                    {['Yes', 'No']?.map((method) => (
                                                        <MenuItem value={method}>{method}</MenuItem>
                                                    ))}
                                                </TextField>
                                                <Check style={{ color: 'green', fontSize: 24, cursor: 'pointer' }} onClick={handleSave} />
                                                <CloseOutlined
                                                    style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                    onClick={resetEditFields}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                role="presentation"
                                                style={{ color: row.isActive ? 'green' : 'red', cursor: 'pointer' }}
                                                onKeyDown={() => console.log('')}
                                                value={field.value}
                                                onClick={(e) => {
                                                    setEditFields({
                                                        active: true,
                                                        id: row.id
                                                    });
                                                }}
                                            >
                                                {row.isActive ? 'Yes' : 'No'}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell style={{ width: 200 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Min Limit:
                                            {editFields.minLimit && editFields.id === row.id ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Min Limit"
                                                        type="number"
                                                        style={{ width: 100, marginRight: 5 }}
                                                        value={field.value}
                                                        onChange={(e) => setField({ label: 'min_limit', value: e.target.value })}
                                                    />
                                                    <Check
                                                        style={{ color: 'green', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={handleSave}
                                                    />
                                                    <CloseOutlined
                                                        style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={resetEditFields}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="presentation"
                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                    onKeyDown={() => console.log('')}
                                                    onClick={(e) => {
                                                        setEditFields({
                                                            active: false,
                                                            dailyLimit: false,
                                                            maxLimit: false,
                                                            minLimit: true,
                                                            id: row.id
                                                        });
                                                    }}
                                                >
                                                    {row.minLimit || 'NA'}
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Max Limit:
                                            {editFields.maxLimit && editFields.id === row.id ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <TextField
                                                        fullWidth
                                                        size="small"
                                                        label="Max Limit"
                                                        type="number"
                                                        style={{ width: 100, marginRight: 5 }}
                                                        value={field.value}
                                                        onChange={(e) => setField({ label: 'max_limit', value: e.target.value })}
                                                    />
                                                    <Check
                                                        style={{ color: 'green', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={handleSave}
                                                    />
                                                    <CloseOutlined
                                                        style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                        onClick={resetEditFields}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="presentation"
                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                    onKeyDown={() => console.log('')}
                                                    onClick={(e) => {
                                                        setEditFields({
                                                            active: false,
                                                            minLimit: false,
                                                            maxLimit: true,
                                                            id: row.id
                                                        });
                                                    }}
                                                >
                                                    {row.maxLimit || 'NA'}
                                                </div>
                                            )}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default PayoutDetails;

import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    CardContent,
    Autocomplete,
    TextField,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchMerchants, fetchIPData } from 'utils/api';
import Loader from 'ui-component/Loader';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyAll } from '@mui/icons-material';

const Secret = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [copy, setCopy] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const [merchants, setMerchants] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState();

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await fetchMerchants();
            result = result.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleGenerate = () => {
        if (!selectedMerchant) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'please select merchant',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        } else {
            setLoading(true);
            axiosServices
                .get(`generateKey/${selectedMerchant?.id}`)
                .then((res) => {
                    setOpen(res?.data?.data?.key);
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while generating secret',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Merchant Secret</div>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Autocomplete
                            id="merchant-select"
                            options={merchants || []}
                            onChange={(e, value) => {
                                setSelectedMerchant(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedMerchant(value);
                                    }}
                                    label="Merchants"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Button style={{ marginLeft: 20 }} size="small" variant="contained" onClick={handleGenerate}>
                            Generate Secret
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-description1"
                >
                    {open && (
                        <>
                            <DialogTitle id="alert-dialog-slide-title1">New Secret</DialogTitle>
                            <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                                <DialogContentText id="alert-dialog-slide-description1">
                                    <Typography style={{ marginRight: 10 }} variant="body2" component="span">
                                        {open}
                                    </Typography>

                                    <CopyToClipboard onCopy={() => setCopy(true)} text={open}>
                                        <CopyAll style={{ fontSize: 20, cursor: 'pointer', marginLeft: 3 }} />
                                    </CopyToClipboard>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button variant="contained" size="small" onClick={handleCloseDialog}>
                                    Ok
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </CardContent>
        </MainCard>
    );
};

export default Secret;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TextField,
    Autocomplete,
    FormControlLabel,
    Switch,
    Typography,
    Alert
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import { Box } from '@mui/system';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddConfig = ({ merchants, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        isVpnEnable: true
    });

    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState({});
    const [newCity, setNewCity] = useState('');

    const validationSchema = yup.object({
        merchantId: open?.merchantId ? null : yup.object().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (Object.keys(cities).length === 0) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Please Add city',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            } else {
                setLoading(true);
                if (open?.merchantId) {
                    axiosServices
                        .put(`update/merchantVpnConfig`, {
                            ...data,
                            city: cities,
                            isVpnEnable: data?.isVpnEnable
                        })
                        .then((res) => {
                            setLoading(false);
                            formik.resetForm();
                            setDetails({});
                            handleCloseDialog();
                            setCities({});
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Saved Successfully',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: false
                                })
                            );
                        })
                        .catch((e) => {
                            setLoading(false);
                            resetForm();
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: e?.message || 'Error while adding details',
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            );
                            dispatch(closeSnackbar);
                        });
                } else {
                    axiosServices
                        .post('create/merchantVpnConfig', {
                            city: cities,
                            isVpnEnable: data?.isVpnEnable,
                            merchantId: data?.merchantId?.id,
                            merchantName: data?.merchantId?.label
                        })
                        .then((res) => {
                            setLoading(false);
                            formik.resetForm();
                            setDetails({});
                            setCities({});
                            handleCloseDialog();
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Saved Successfully',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: false
                                })
                            );
                        })
                        .catch((e) => {
                            setLoading(false);
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: e?.message || 'Error while adding details',
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            );
                            dispatch(closeSnackbar);
                        });
                }
            }
        }
    });

    useEffect(() => {
        if (open?.merchantId) {
            setDetails(open);
            setCities(open?.city || {});
        }
    }, [open]);

    // Add a new city with a default boolean value of false
    const handleAddCity = () => {
        if (newCity.trim() === '') {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Please Add city',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            ); // Do not add an empty city name
        } else {
            if (Object.prototype.hasOwnProperty.call(cities, newCity)) return; // Avoid duplicate cities
            setCities({
                ...cities,
                [newCity]: false // Default value for new city is false
            });
            setNewCity(''); // Reset the input field
        }
    };

    // Handle switch toggle for a specific city
    const handleSwitchChange = (cityName) => (event) => {
        setCities({
            ...cities,
            [cityName]: event.target.checked
        });
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="sm" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Merchant VPN</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                {open?.merchantId ? (
                                    <TextField
                                        fullWidth
                                        id="merchantId"
                                        name="merchantId"
                                        label="Merchant"
                                        disabled
                                        value={open?.merchantName || null}
                                    />
                                ) : (
                                    <Autocomplete
                                        id="merchantId"
                                        className="merchantId-select"
                                        name="merchantId"
                                        options={merchants?.length ? merchants : []}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('merchantId', value);
                                        }}
                                        // value={formik.values.merchantId}
                                        // onChange={formik.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('merchantId', value);
                                                }}
                                                label="Merchant Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            size="small"
                                            onChange={(e) => {
                                                formik.setFieldValue('isVpnEnable', e.target.checked);
                                            }}
                                            checked={formik.values.isVpnEnable}
                                        />
                                    }
                                    label="VPN Enabled"
                                />
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', marginTop: 2 }}>
                                    <TextField
                                        style={{ marginRight: 20 }}
                                        label="Enter City"
                                        size="small"
                                        value={newCity}
                                        onChange={(e) => setNewCity(e.target.value)}
                                    />
                                    <Button size="small" variant="contained" onClick={handleAddCity}>
                                        Add City
                                    </Button>
                                </Box>

                                {Object.keys(cities).length > 0 && (
                                    <Box sx={{ mt: 2 }}>
                                        <Grid container>
                                            {Object.keys(cities).map((cityName) => (
                                                <Grid item xs={6}>
                                                    <Box
                                                        key={cityName}
                                                        sx={{ marginLeft: 2, mb: 2, display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <Typography sx={{ marginRight: 10, width: 100 }} variant="subtitle1">
                                                            {cityName}
                                                        </Typography>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={cities[cityName]}
                                                                    onChange={handleSwitchChange(cityName)}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                setCities({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddConfig.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddConfig;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Slide,
    TextField,
    MenuItem,
    Autocomplete
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    merchantId: yup.object().required('Required Field').nullable(),
    ipValue: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddIPWhitelist = ({ merchants, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({});

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            if (open) {
                setLoading(true);
                axiosServices
                    .post('doWhiteListIpForMerchant', { ...data, merchantId: data.merchantId.id, status: 'Active' })
                    .then((res) => {
                        setLoading(false);
                        resetForm();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.label) {
            setDetails(open);
        }
    }, [open]);

    console.log(formik.values, formik.errors);

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="xs" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Add IP Whitelist</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            {!open?.label && (
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            id="merchantId"
                                            className="merchantId-select"
                                            name="merchantId"
                                            options={merchants?.length ? merchants : []}
                                            onChange={(e, value) => {
                                                formik.setFieldValue('merchantId', value);
                                            }}
                                            // onChange={formik.handleChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onChange={(e, value) => {
                                                        formik.setFieldValue('merchantId', value);
                                                    }}
                                                    label="Merchant Name"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="ipValue"
                                            name="ipValue"
                                            label="IP"
                                            value={formik.values.ipValue}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.ipValue)}
                                            helperText={formik.errors.ipValue}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddIPWhitelist.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddIPWhitelist;

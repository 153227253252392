import { SuperAdminDashboard, AdminDashboard, AgentDashboard, SubAdminDashboard } from './dashboard';
import { SuperAdminPayment, AdminPayment, AgentPayment, SubAdminPayment } from './payment';
import support, { AgentSupport, SubAdminSupport } from './support';
import Developer from './developer';

// ==============================|| MENU ITEMS ||============================== //

export const SuperAdminMenuItems = {
    items: [SuperAdminDashboard, SuperAdminPayment, support]
};
export const AdminMenuItems = {
    items: [AdminDashboard, AdminPayment, support]
};
export const AgentMenuItems = {
    items: [AgentDashboard, AgentPayment, AgentSupport]
};
export const DeveloperMenuItems = {
    items: [Developer]
};
export const SubAdminMenuItems = {
    items: [SubAdminDashboard, SubAdminPayment, SubAdminSupport]
};

export default SuperAdminMenuItems;

import React, { useEffect, useState } from 'react';

// material-ui
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Tooltip,
    TablePagination,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchMerchantAffiliates, fetchMerchantAffiliatesById } from 'utils/api';
import { RefreshOutlined } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import axiosServices from 'utils/axios';

// table header options
const headCells = [
    {
        id: 'Affiliate',
        numeric: false,
        label: 'Affiliate',
        align: 'center'
    },
    {
        id: 'action',
        numeric: false,
        label: 'Action',
        align: 'center'
    }
];

const Affiliates = () => {
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const fetcMerchantsFromAPI = async () => {
        setLoading(true);
        try {
            setLoading(false);
            const result = await fetchMerchantAffiliates();
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const fetchMerchantById = async (id) => {
        setLoading(true);
        try {
            const result = await fetchMerchantAffiliatesById(id);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Active Merchant Affiliates</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <Dialog open={open} keepMounted onClose={() => setOpen(false)}>
                <DialogTitle id="alert-dialog-slide-title1">Merchants</DialogTitle>
                <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                    <DialogContentText id="alert-dialog-slide-description1">
                        <AffilateLinking onClose={() => setOpen(false)} id={open?.affiliateId} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            // const affilaite = fetchMerchantById(row.affiliateId);
                            return (
                                <TableRow hover>
                                    <TableCell align="center" style={{ fontWeight: 500 }}>
                                        <div>
                                            {row.username} ({row.email}){' '}
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Show Merchants">
                                            <IconButton color="primary" aria-label="link">
                                                <div
                                                    role="presentation"
                                                    style={{ fontSize: 12, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                    onClick={() => setOpen(row)}
                                                    onKeyDown={() => setOpen(row)}
                                                >
                                                    Show Merchants
                                                </div>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

const AffilateLinking = ({ id, onClose }) => {
    const [loading, setLioading] = React.useState(false);
    const [linkedAgents, setLinkedAgents] = React.useState([]);

    const getAllLinkedAgents = (id) => {
        setLioading(true);
        axiosServices
            .get(`getAffiliateMerchantByAffiliateId/${id}`)
            .then((res) => {
                setLinkedAgents(res.data?.data || []);
                setLioading(false);
            })
            .catch((e) => {
                setLioading(false);
            });
    };

    React.useEffect(() => {
        if (id) {
            getAllLinkedAgents(id);
        }
    }, [id]);

    console.log(linkedAgents);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {linkedAgents?.length ? (
                        linkedAgents?.map((res) => (
                            <div style={{ marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    {res.merchantName} ({res.merchantId}){/* {res.affiliateId || ''} */}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No Merchants Found</div>
                    )}
                </div>
            )}
        </>
    );
};

export default Affiliates;

import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddConfig = ({ merchants, open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const [details, setDetails] = useState({
        // processingTimeMinutes: 0,
        // englishDescription: 0,
        // hindiDescription: 35
    });

    const [file, setFile] = useState();

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        merchantId: open?.merchantId ? null : yup.object().required('Required Field').nullable()
    });

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            setLoading(true);

            if (open?.merchantId) {
                axiosServices
                    .put(`updateMerchantProfileMeta/${data?.merchantId}`, {
                        ...data,
                        merchantId: data?.merchantId?.id,
                        logoBase64: file ? file.split('base64,')?.[1] : data?.logoBase64
                    })
                    .then((res) => {
                        setLoading(false);
                        setFile();
                        formik.resetForm();
                        setDetails({});
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        resetForm();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            } else {
                axiosServices
                    .post('createMerchantProfileMeta', {
                        ...data,
                        merchantId: data?.merchantId?.id,
                        logoBase64: file ? file.split('base64,')?.[1] : ''
                    })
                    .then((res) => {
                        setLoading(false);
                        formik.resetForm();
                        setDetails({});
                        setFile();
                        handleCloseDialog();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Saved Successfully',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    })
                    .catch((e) => {
                        setLoading(false);
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: e?.message || 'Error while adding details',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                        dispatch(closeSnackbar);
                    });
            }
        }
    });

    useEffect(() => {
        if (open?.merchantId) {
            setDetails(open);
        }
    }, [open]);

    const onLoad = (fileString) => {
        setFile(fileString);
    };

    const handleUploadLogo = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            onLoad(reader.result);
        };
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} fullWidth="true" maxWidth="sm" keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Merchant Notes</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={12}>
                                {open?.merchantId ? (
                                    <TextField
                                        fullWidth
                                        id="merchantId"
                                        name="merchantId"
                                        label="Merchant"
                                        disabled
                                        value={open?.merchantName || null}
                                    />
                                ) : (
                                    <Autocomplete
                                        id="merchantId"
                                        className="merchantId-select"
                                        name="merchantId"
                                        options={merchants?.length ? merchants : []}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('merchantId', value);
                                        }}
                                        // value={formik.values.merchantId}
                                        // onChange={formik.handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={(e, value) => {
                                                    formik.setFieldValue('merchantId', value);
                                                }}
                                                label="Merchant Name"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id="englishDescription"
                                    name="englishDescription"
                                    label="English Description"
                                    value={formik.values.englishDescription || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.englishDescription)}
                                    helperText={formik.errors.englishDescription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    id="hindiDescription"
                                    rows={4}
                                    name="hindiDescription"
                                    label="Hindi Description"
                                    value={formik.values.hindiDescription || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.hindiDescription)}
                                    helperText={formik.errors.hindiDescription}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="videoLinkUpi"
                                    name="videoLinkUpi"
                                    label="Video Link (UPI)"
                                    value={formik.values.videoLinkUpi || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.videoLinkUpi)}
                                    helperText={formik.errors.videoLinkUpi}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="videoLinkImps"
                                    name="videoLinkImps"
                                    label="Video Link (IMPS)"
                                    value={formik.values.videoLinkImps || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.videoLinkImps)}
                                    helperText={formik.errors.videoLinkImps}
                                />
                            </Grid>
                            <Grid style={{ fontWeight: 500, marginTop: 10 }} item xs={12}>
                                Upload Logo
                                <input style={{ marginLeft: 10 }} type="file" accept="image/*" onChange={handleUploadLogo} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddConfig.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddConfig;

import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project imports
import MainCard from './MainCard';

// ============================|| HOVER DATA CARD ||============================ //

const HoverDataCard = ({ title, iconPrimary, primary, secondary, color }) => {
    const IconPrimary = iconPrimary;
    const primaryIcon = iconPrimary !== undefined ? <IconPrimary fontSize="large" sx={{ width: 18, height: 18, color }} /> : null;

    return (
        <MainCard>
            <Grid
                // sx={{ border: '1px solid lightgray', padding: 1, py: 2 }}
                container
                justifyContent="space-between"
                direction="column"
                alignItems="center"
            >
                <Grid item sm={12}>
                    <Typography variant="h6" color="inherit">
                        {title}
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mt: 1.75, mx: 'auto' }}>
                        {primaryIcon}
                        <Typography variant="h4">{primary}</Typography>
                    </Stack>
                </Grid>
                {secondary && (
                    <Grid item sm={12}>
                        <Typography variant="body2" color="textSecondary">
                            {secondary}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </MainCard>
    );
};

HoverDataCard.propTypes = {
    title: PropTypes.string,
    iconPrimary: PropTypes.object,
    primary: PropTypes.number,
    secondary: PropTypes.string,
    color: PropTypes.string
};

export default HoverDataCard;

import { Check, CloseOutlined, Delete } from '@mui/icons-material';
import {
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Stack, useTheme } from '@mui/system';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';

import { fetchMerchantMeta } from 'utils/api';
import axiosServices from 'utils/axios';

// table header options
const headCells = [
    {
        id: 'mmpid',
        label: 'ID',
        align: 'left'
    },
    {
        id: 'pginfo',
        label: 'Account',
        align: 'left'
    },
    {
        id: 'active',
        label: 'Limit',
        align: 'left'
    },
    {
        id: 'active',
        label: 'Active',
        align: 'left'
    },
    {
        id: 'date',
        label: 'Date',
        align: 'left'
    }
];

// ==============================|| MERCHANT BANKS ||============================== //

const MerchantBanks = ({ id, refetch }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [field, setField] = React.useState({
        label: '',
        value: ''
    });

    const [editFields, setEditFields] = React.useState({
        active: false,
        minLimit: false,
        maxLimit: false,
        currentTurnover: false,
        id: ''
    });

    const fetchDataFromAPI = async (id) => {
        try {
            const result = await fetchMerchantMeta(id);
            if (result?.length) {
                result.sort((a, b) => b.active - a.active);
            }
            setRows(result || []);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (id) {
            fetchDataFromAPI(id);
        }
    }, [id, refetch]);

    const resetEditFields = () => {
        setEditFields({ active: false, minLimit: false, maxLimit: false, currentTurnover: false, id: '' });
        setField({ label: '', value: '' });
    };

    const handleSave = () => {
        axiosServices
            .put('updateMerchantPayinMappingWithLabel', { mappingId: editFields.id, label: field.label, value: field.value })
            .then((res) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Field updated successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
                resetEditFields();
                fetchDataFromAPI(id);
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating field',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const deleteMapping = (id) => {
        axiosServices
            .delete(`deletePayinMerchantMapping/${id}`)
            .then((res) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Mapping deleted successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );

                dispatch(closeSnackbar);
                window.location.reload();
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while deleting mapping',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <div>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                            <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                                    Action
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>
                                    <div>Account Id: {row.pgAccountId}</div>
                                    <div>Label Id: {row.pgLabelId}</div>
                                    <div>Label Name: {row.pgLabelName}</div>
                                </TableCell>
                                <TableCell>
                                    <div>Daily Limit: {row.dailyLimit}</div>
                                    <div>Current Balance: {row.currentBalance}</div>
                                    <div>Current Turnover: {row.currentTurnover}</div>
                                </TableCell>
                                <TableCell>
                                    {editFields.active && editFields.id === row.id ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                select
                                                id="active"
                                                size="small"
                                                name="active"
                                                label="Status"
                                                style={{ width: 100, marginRight: 5 }}
                                                // value={field.active ? 'Yes' : 'No'}
                                                onChange={(e) => setField({ label: 'is_active', value: e.target.value === 'Yes' })}
                                            >
                                                {['Yes', 'No']?.map((method) => (
                                                    <MenuItem value={method}>{method}</MenuItem>
                                                ))}
                                            </TextField>
                                            <Check style={{ color: 'green', fontSize: 24, cursor: 'pointer' }} onClick={handleSave} />
                                            <CloseOutlined
                                                style={{ color: 'red', fontSize: 24, cursor: 'pointer' }}
                                                onClick={resetEditFields}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            role="presentation"
                                            style={{ color: row.active ? 'green' : 'red', cursor: 'pointer' }}
                                            onKeyDown={() => console.log('')}
                                            value={field.value}
                                            onClick={(e) => {
                                                setEditFields({
                                                    dailyLimit: false,
                                                    maxLimit: false,
                                                    minLimit: false,
                                                    active: true,
                                                    id: row.id
                                                });
                                            }}
                                        >
                                            {row.active ? 'Yes' : 'No'}
                                        </div>
                                    )}
                                </TableCell>
                                <TableCell>
                                    <div>Created On: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</div>
                                    <div>Updated On: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                </TableCell>
                                <TableCell align="center">
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton color="primary" aria-label="delete" size="large">
                                                <Delete onClick={() => deleteMapping(row.id)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                        {rows?.length === 0 && <div style={{ marginTop: 10, color: 'red' }}>No Data</div>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default MerchantBanks;

import React, { useEffect } from 'react';

// material-ui
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Chip,
    Tooltip,
    CardContent,
    Grid,
    TextField,
    Button
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { getDebitBankTxn } from 'utils/api';
import { Download, RefreshOutlined } from '@mui/icons-material';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ISTDate } from 'constant';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import axiosServicesNew from 'utils/axios_new';

// table header options
const headCells = [
    {
        id: 'date',
        numeric: true,
        label: 'Date',
        align: 'left'
    },
    {
        id: 'Bank',
        numeric: false,
        label: 'Bank',
        align: 'left'
    },
    {
        id: 'Debited Account',
        numeric: false,
        label: 'Debited Account',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'UTR',
        numeric: true,
        label: 'UTR',
        align: 'left'
    },
    {
        id: 'Transaction ID',
        numeric: false,
        label: 'Transaction ID',
        align: 'left'
    },
    {
        id: 'date txn',
        numeric: true,
        label: 'Txn Date',
        align: 'left'
    },
    {
        id: 'method',
        numeric: true,
        label: 'Method',
        align: 'left'
    }
];

const currentDate = ISTDate;

const Transactions = () => {
    const dispatch = useDispatch();

    const { debitBankId } = useParams();
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const fetchDataFromAPI = async (debitBankId, page, rowsPerPage, startDate, endDate) => {
        setLoading(true);
        try {
            const result = await getDebitBankTxn(debitBankId, page, rowsPerPage, startDate, endDate);
            setLoading(false);
            setRows(result?.data?.transactionList || []);
            setData(result?.data);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(debitBankId, page, rowsPerPage, startDate, endDate);
    }, [debitBankId, page, rowsPerPage, startDate, endDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const onRefresh = () => {
        setRows([]);
        fetchDataFromAPI(debitBankId, page, rowsPerPage, startDate, endDate);
    };

    const handleGenerateReport = () => {
        setLoading(true);
        const date1 = new Date(startDate).getTime();
        const date2 = new Date(endDate).getTime();
        if (date1 <= date2) {
            axiosServicesNew
                .get(`getBankTransactionReportByDebitBankId/${debitBankId}?startDate=${startDate}&endDate=${endDate}`)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e.message,
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'End date cannot be less than Start date',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };
    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Debit Bank Transactions</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent sx={{ paddingBottom: '10px 0px 10px 10px' }}>
                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} alignItems="center" spacing={2}>
                    <Grid
                        container
                        style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}
                        item
                        xs={12}
                        sm={12}
                        alignItems="baseline"
                        spacing={2}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const stDate = dayjs(dt).format('YYYY-MM-DD');
                                    setStartDate(stDate);
                                }}
                                maxDate={ISTDate}
                                renderInput={(params) => <TextField sx={{ width: 210, mr: 2 }} size="small" {...params} />}
                                label="Start Date"
                            />
                            <DatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate}
                                minDate={startDate}
                                maxDate={ISTDate}
                                onChange={(value) => {
                                    const dt = new Date(value);
                                    const enDate = dayjs(dt).format('YYYY-MM-DD');
                                    setEndDate(enDate);
                                }}
                                renderInput={(params) => <TextField sx={{ width: 210, mr: 2, ml: 2 }} size="small" {...params} />}
                                label="End Date"
                            />
                        </LocalizationProvider>
                        <Button size="small" variant="contained" sx={{ mr: '1rem', ml: 2, mt: 1 }} onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />

                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell style={{ fontWeight: 500 }} component="th" id={labelId} scope="row">
                                        <div style={{ width: 180 }}>
                                            Created: {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                        <div>Updated: {row.updatedAt ? new Date(row.updatedAt).toLocaleString() : ''}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <div>Acc Holder: {row.accountHolderName}</div>
                                            <div>Bank: {row.bankName}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            <div>ID: {row.bankTransactionId?.bankId}</div>
                                            <div>Acc Holder: {row.debitedAccountHolderName}</div>
                                            <div>Bank: {row.debitedBankName}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.transactionUTR}</TableCell>
                                    <TableCell>{row.bankTransactionId?.bankTransactionId}</TableCell>
                                    <TableCell>{row.txnDate}</TableCell>
                                    <TableCell>{row.payMethod}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={data?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default Transactions;

import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    TextField,
    Tooltip,
    Autocomplete,
    IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchSettlement, fetchActiveMerchants, fetchSettlementByMerchant } from 'utils/api';
import { useDispatch } from 'react-redux';
import { Delete, Edit, RefreshOutlined } from '@mui/icons-material';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import AddSettlement from './AddSettlement';
import ConfirmationModal from 'ui-component/Modal';
import axiosServices from 'utils/axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ISTDate } from 'constant';

// table header options
const headCells = [
    {
        id: 'id',
        numeric: false,
        label: 'Id',
        align: 'center'
    },
    {
        id: 'merchant',
        numeric: false,
        label: 'Merchant',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: true,
        label: 'Settled Amount',
        align: 'left'
    },
    {
        id: 'remark',
        numeric: false,
        label: 'Remark',
        align: 'left'
    },
    {
        id: 'flowType',
        numeric: false,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'date',
        numeric: false,
        label: 'Date',
        align: 'left'
    },
    {
        id: 'createdBy',
        numeric: false,
        label: 'Created By',
        align: 'left'
    },
    {
        id: 'Action',
        numeric: false,
        label: 'Action',
        align: 'center'
    }
];

const currentDate = ISTDate;

const CBSettlement = () => {
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [merchants, setMerchants] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [deleteTxn, setDeleteTxn] = React.useState(false);

    const [merchant, setMerchant] = React.useState();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const fetchDataFromAPI = async (id, page, rowsPerPage, startDate, endDate) => {
        setLoading(true);
        try {
            const result = id
                ? await fetchSettlementByMerchant(id, page || 0, rowsPerPage || 50, startDate, endDate, 'CHARGEBACK_SETTLEMENT')
                : await fetchSettlement(page, rowsPerPage, startDate, endDate, 'CHARGEBACK_SETTLEMENT');
            setLoading(false);
            setAllData(result?.data);
            setRows(result?.data?.BankList || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await fetchActiveMerchants();
            result = result?.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(merchant?.id, page, rowsPerPage, startDate, endDate);
    }, [page, rowsPerPage, merchant, startDate, endDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(merchant?.id, page, rowsPerPage, startDate, endDate);
    };

    const handleDeleteTxn = () => {
        setLoading(true);
        axiosServices
            .delete(`deletedSettledTxn/${deleteTxn?.manualSettledEntityId?.merchantId}/${deleteTxn?.manualSettledEntityId?.settlementId}`)
            .then((res) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Transaction Deleted',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                dispatch(closeSnackbar);
                setDeleteTxn(false);
                fetchDataFromAPI(merchant?.id, page, rowsPerPage);
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while deleting transaction',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const onRefresh = () => {
        setRows([]);
        fetchDataFromAPI(merchant?.id, page, rowsPerPage, startDate, endDate);
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Merchant Chargeback Settlement</div>
                    <Tooltip title="Refresh">
                        <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </div>
            }
            content={false}
        >
            {loading && <Loader />}
            <AddSettlement merchants={merchants} open={open} handleCloseDialog={handleCloseDialog} />
            <CardContent>
                <Grid sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2}>
                        <Autocomplete
                            id="merchantId"
                            className="merchantId-select"
                            name="merchantId"
                            options={merchants?.length ? merchants : []}
                            onChange={(e, value) => {
                                setMerchant(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e, value) => {
                                        setMerchant(value);
                                    }}
                                    size="small"
                                    style={{ width: 200 }}
                                    label="Merchant Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button variant="contained" onClick={() => setOpen(true)}>
                            Add Settlement
                        </Button>
                    </Grid> */}
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        inputFormat="YYYY/MM/DD"
                        value={startDate}
                        onChange={(value) => {
                            const dt = new Date(value);
                            const stDate = dayjs(dt).format('YYYY-MM-DD');
                            setStartDate(stDate);
                        }}
                        maxDate={ISTDate}
                        renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                        label="Start Date"
                    />
                    <DatePicker
                        inputFormat="YYYY/MM/DD"
                        value={endDate}
                        minDate={startDate}
                        maxDate={ISTDate}
                        onChange={(value) => {
                            const dt = new Date(value);
                            const enDate = dayjs(dt).format('YYYY-MM-DD');
                            setEndDate(enDate);
                        }}
                        renderInput={(params) => <TextField sx={{ width: 180, mr: '1rem' }} size="small" {...params} />}
                        label="End Date"
                    />
                </LocalizationProvider>
            </CardContent>
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell align="center" component="th" id={labelId} scope="row">
                                        {row.manualSettledEntityId?.settlementId}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 500 }}>
                                        <div>{row.manualSettledEntityId?.merchantId}</div>
                                        <div>{row.name}</div>
                                    </TableCell>
                                    <TableCell>{row.settledAmount}</TableCell>
                                    <TableCell>{row.remark}</TableCell>
                                    <TableCell>{row.flowType}</TableCell>
                                    <TableCell>{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                    <TableCell>{row.createdBy}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Delete onClick={() => setDeleteTxn(row)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                open={deleteTxn}
                title="Delete Transaction"
                subTitle="Are you sure, you want to delete selected Transaction"
                handleClose={() => setDeleteTxn(false)}
                handleSubmit={handleDeleteTxn}
                loading={loading}
            />

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default CBSettlement;

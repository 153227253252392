import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconArrowDownRight, IconArrowUpRight, IconBell } from '@tabler/icons';
import styled from '@emotion/styled';
import axiosServices from 'utils/axios';

// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

const chipSX = {
    height: 24,
    padding: '0 6px'
};

const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState();
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        axiosServices.get('getNotificationData').then((res) => {
            setNotifications(res?.data?.data);
        });
    }, []);

    // useEffect(() => {
    //     axiosServices.get('getNotificationData').then((res) => {
    //         setNotifications(res?.data?.data);
    //     });
    // }, []);

    return (
        <>
            <Box
                sx={{
                    ml: 0.5,
                    mr: 2,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge badgeContent={notifications?.totalRequestCount || 0} color="secondary">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open && notifications?.totalRequestCount}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography variant="subtitle1">All Notification</Typography>
                                                            {/* <Chip
                                                                size="small"
                                                                label="20"
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            /> */}
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{
                                                        height: '100%',
                                                        maxHeight: 'calc(100vh - 205px)',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item xs={12} p={0}>
                                                            <Divider sx={{ my: 0 }} />
                                                        </Grid>
                                                    </Grid>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            maxWidth: 330,
                                                            py: 0,
                                                            borderRadius: '10px',
                                                            [theme.breakpoints.down('md')]: {
                                                                maxWidth: 300
                                                            },
                                                            '& .MuiListItemSecondaryAction-root': {
                                                                top: 22
                                                            },
                                                            '& .MuiDivider-root': {
                                                                my: 0
                                                            },
                                                            '& .list-container': {
                                                                pl: 7
                                                            }
                                                        }}
                                                    >
                                                        {notifications?.payoutProcessRequest ? (
                                                            <ListItemWrapper>
                                                                <ListItem alignItems="center">
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            sx={{
                                                                                color: theme.palette.success.dark,
                                                                                backgroundColor:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? theme.palette.dark.main
                                                                                        : theme.palette.success.light,
                                                                                border:
                                                                                    theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                                                borderColor: theme.palette.success.main
                                                                            }}
                                                                        >
                                                                            <IconArrowUpRight stroke={1.5} size="20px" />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <Typography
                                                                        onClick={() => navigate('/support/payout-request?processing=true')}
                                                                        variant="subtitle1"
                                                                    >
                                                                        Payout Request ({notifications?.payoutProcessRequest})
                                                                    </Typography>
                                                                </ListItem>
                                                            </ListItemWrapper>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Divider />
                                                        {notifications?.settlementProcessRequest ? (
                                                            <ListItemWrapper>
                                                                <ListItem alignItems="center">
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            sx={{
                                                                                color: theme.palette.success.dark,
                                                                                backgroundColor:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? theme.palette.dark.main
                                                                                        : theme.palette.success.light,
                                                                                border:
                                                                                    theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                                                borderColor: theme.palette.success.main
                                                                            }}
                                                                        >
                                                                            <IconArrowDownRight stroke={1.5} size="20px" />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <Typography
                                                                        onClick={() => navigate('/support/merchant?processing=true')}
                                                                        variant="subtitle1"
                                                                    >
                                                                        Merchant Settlement Request (
                                                                        {notifications?.settlementProcessRequest})
                                                                    </Typography>
                                                                </ListItem>
                                                            </ListItemWrapper>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Divider />
                                                        {notifications?.affiliateSettlementProcessRequest ? (
                                                            <ListItemWrapper>
                                                                <ListItem alignItems="center">
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            sx={{
                                                                                color: theme.palette.success.dark,
                                                                                backgroundColor:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? theme.palette.dark.main
                                                                                        : theme.palette.success.light,
                                                                                border:
                                                                                    theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                                                                borderColor: theme.palette.success.main
                                                                            }}
                                                                        >
                                                                            <IconArrowDownRight stroke={1.5} size="20px" />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <Typography
                                                                        onClick={() => navigate('/support/affiliate?processing=true')}
                                                                        variant="subtitle1"
                                                                    >
                                                                        Affiliate Settlement Request (
                                                                        {notifications?.affiliateSettlementProcessRequest})
                                                                    </Typography>
                                                                </ListItem>
                                                            </ListItemWrapper>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </List>
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation>
                                                View All
                                            </Button>
                                        </CardActions> */}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
